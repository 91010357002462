import {appSyncQuery} from "./index";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import {Dispute, Disputes, MoveDirection, QueryPair, SortDirection} from "../GraphQL/backoffice";
import {DisputeEvidence} from "../GraphQL/internal_types";

const listString = `query ListDisputes($direction: MoveDirection, $offset_id: String, $offset: String, $query: SqlQuery, $limit: Int = 10) {
  disputes(direction: $direction, limit: $limit, offset: $offset, offset_id: $offset_id, query: $query) {
    items {
      merchant_uid
      transaction_id
      dispute_id
      status
      reason
      amount
      dispute_date
      evidence_last_send_date
      updated_date
      expiration_date
      account_code
      reference
      last_four
      card_brand
      full_name
      reason_message
      settlement_batch
      settlement_withdrawal_batch
      settlement_deposit_batch
      transaction_date
      phone
      email
    }
    total_row_count
  }
}
`

export const list = (order: SortDirection, offset: Dispute | null, limit: number, filter: QueryPair[], direction: MoveDirection): Promise<GraphQLResult<{disputes: Disputes}>> => {
    let sort = [{key: 'dispute_date', direction: order}]
    const queryObject = {
        query_list: filter,
        sort_list: sort,
    }
    const variables = {
        query: queryObject,
        offset_id: offset?.transaction_id,
        offset: offset?.dispute_date,
        limit: limit,
        direction: direction
    }
    return appSyncQuery(listString, variables)
}

const listEvidenceString = `query ListEvidence($dispute_id: String!, $merchant_uid: ID!) {
  disputeEvidence(dispute_id: $dispute_id, merchant_uid: $merchant_uid) {
    dispute_id
    merchant_uid
    filename
    evidence_date
    status
    updated_date
  }
}`

export const listEvidence = (dispute_id: string, merchant_uid: string): Promise<GraphQLResult<{disputeEvidence: DisputeEvidence[]}>> => {
    const variables = {
        dispute_id: dispute_id,
        merchant_uid: merchant_uid
    }
    return appSyncQuery(listEvidenceString, variables, true)
}

const uploadEvidenceString = `query UploadEvidence($mime_types: [String!], $dispute_id: String!, $filenames: [String]!, $merchant_uid: ID!) {
  disputeEvidenceTokens(mime_types: $mime_types, dispute_id: $dispute_id, filenames: $filenames, merchant_uid: $merchant_uid, action: PUT)
}`

export const uploadEvidence = (dispute_id: string, filenames: string[], content_types: string[], merchantUid: string): Promise<GraphQLResult<{disputeEvidenceTokens: string[]}>> => {
    const variables = {
        dispute_id: dispute_id,
        filenames: filenames,
        mime_types: content_types,
        merchant_uid: merchantUid
    }
    return appSyncQuery(uploadEvidenceString, variables, true)
}

const downloadEvidenceString = `query DownloadEvidence($dispute_id: String!, $filenames: [String]!, $merchant_uid: ID!) {
  disputeEvidenceTokens(dispute_id: $dispute_id, filenames: $filenames, merchant_uid: $merchant_uid, action: GET)
}`

export const downloadEvidence = (dispute_id: string, filenames: string[], merchantUid: string): Promise<GraphQLResult<{disputeEvidenceTokens: string[]}>> => {
    const variables = {
        dispute_id: dispute_id,
        filenames: filenames,
        merchant_uid: merchantUid
    }
    return appSyncQuery(downloadEvidenceString, variables, true)
}

const deleteEvidenceString = `mutation DeleteEvidence($dispute_id: String!, $filename: String!, $merchant_uid: ID!) {
  deleteDisputeEvidence(dispute_id: $dispute_id, filename: $filename, merchant_uid: $merchant_uid)
}`

export const deleteEvidence = (dispute_id: string, filename: string, merchant_uid: string): Promise<GraphQLResult<{deleteDisputeEvidence: boolean}>> => {
    const variables = {
        dispute_id: dispute_id,
        filename: filename,
        merchant_uid: merchant_uid
    }
    return appSyncQuery(deleteEvidenceString, variables, true)
}

const sendEvidenceString = `mutation SendEvidence($merchant_uid: ID = "", $dispute_id: String = "") {
  createSendDisputeEvidence(dispute_id: $dispute_id, merchant_uid: $merchant_uid)
}`

export const sendEvidence = (dispute_id: string, merchant_uid: string): Promise<GraphQLResult<{createSendDisputeEvidence: boolean}>> => {
    const variables = {
        dispute_id: dispute_id,
        merchant_uid: merchant_uid
    }
    return appSyncQuery(sendEvidenceString, variables, true)
}