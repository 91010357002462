import { appSyncQuery } from './index'
import {GraphQLResult} from "@aws-amplify/api-graphql";
import {Merchant, MerchantSettingsInput} from "../GraphQL/backoffice";
import {PaymentParameters} from "../GraphQL/internal_types";

const queryString = `query getMerchant($merchant_uid: String) {
  merchant(merchant_uid: $merchant_uid) {
    settings {
      contact_email
      contact_phone
      website
      twitter
      tiktok
      instagram
      facebook
      linkedin
    }
    parent_merchant_uid
    merchant_uid
    merchant_name
    is_system
    fee_model {
      interchange_plus
      merchant_fee {
        ach_basis
        ach_fixed
        card_basis
        card_fixed
      }
      service_fee {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
      service_fee_min {
        card_basis
        card_fixed
        ach_basis
        ach_fixed
      }
    }
    cash_active
    card_active
    api_key
    ach_active
    submitted_onboarding
  }
}
`

export const query = (uid: string): Promise<GraphQLResult<{merchant: Merchant}>>  => {
    const variables = {
        merchant_uid: uid
    }
    return appSyncQuery(queryString, variables)
}


const paymentParamsString = `query GetPaymentParams {
  defaultPaymentParameters {
    maximum_occurrence
    validation_link_url
    denied_before_date
    denied_after_date
    maximum_amount
    expires_in
    payment_parameters_name
    minimum_amount
    recurrence_period
  }
}`

export const paymentParams = (): Promise<GraphQLResult<{defaultPaymentParameters: PaymentParameters}>> => {
    return appSyncQuery(paymentParamsString, {}, true)
}

const updateMerchantSettingsString = `mutation UpdateMerchantSettings($merchant_uid: ID!, $contact_email: AWSEmail, $contact_phone: AWSPhone, $facebook: AWSURL, $instagram: AWSURL, $linkedin: AWSURL, $tiktok: AWSURL, $twitter: AWSURL, $website: AWSURL) {
  updateMerchantSettings(merchant_uid: $merchant_uid, settings: {contact_email: $contact_email, contact_phone: $contact_phone, facebook: $facebook, instagram: $instagram, linkedin: $linkedin, twitter: $twitter, website: $website, tiktok: $tiktok})
}`

export const updateMerchantSettings = (merchant_uid: string, settings: MerchantSettingsInput): Promise<GraphQLResult<{updateMerchantSettings: boolean}>> => {
    const variables = {
        merchant_uid,
        ...settings
    }
    return appSyncQuery(updateMerchantSettingsString, variables)
}