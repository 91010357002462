import {appSyncQuery} from "./index";
import {
    FeeMode,
    Invoice, InvoiceSettingsInput, InvoicesWithQuery,
    MoveDirection, OfflineTransaction,
    OfflineTransactionType,
    Operator,
    QueryPair,
    SortDirection
} from "../GraphQL/backoffice";
import {GraphQLResult} from "@aws-amplify/api-graphql";
const listString = `query ListInvoices($direction: MoveDirection = FORWARD, $limit: Int = 10, $offset: String = "", $offset_id: String = "", $query: SqlQuery = {}, $payor_query: [QueryPair] = {}) {
  invoices(direction: $direction, limit: $limit, offset: $offset, offset_id: $offset_id, query: $query) {
    items {
      merchant_uid
      created_date
      due_by
      fee_mode
      invoice_amount
      invoice_description
      invoice_date
      invoice_id
      invoice_name
      is_secure
      merchant_invoice_number
      payor(query_list: $payor_query) {
        payor_id
        full_name
        address_line1
        address_line2
        country
        region
        city
        postal_code
        email
        phone
      }
      security_pin
      settings {
        accepted_payment_methods {
          ach
          card
          cash
        }
      }
      account_code
      reference
      require_payor_address
      status
      total_paid_amount
    }
    total_row_count
  }
}
`

export const list = (order: SortDirection, offset: Invoice | null, limit: number, filter: {invoiceQuery: QueryPair[], payorQuery: QueryPair[]}, direction: MoveDirection): Promise<GraphQLResult<{invoices: InvoicesWithQuery}>> => {
    let sort = [{key: 'created_date', direction: order}]
    const queryObject = {
        query_list: filter.invoiceQuery,
        sort_list: sort,
    }
    const variables = {
        query: queryObject,
        offset_id: offset?.invoice_id,
        offset: offset?.created_date,
        limit: limit,
        direction: direction,
        payor_query: filter.payorQuery,
    }
    return appSyncQuery(listString, variables)
}

const getString = `query GetInvoice($query: SqlQuery = {}) {
  invoices(query: $query) {
    items {
      merchant_uid
      created_date
      due_by
      fee_mode
      invoice_amount
      invoice_description
      invoice_date
      invoice_id
      invoice_name
      is_secure
      merchant_invoice_number
      offline_transactions {
        amount
        instance_id
        invoice_id
        note
        transaction_date
        type
      }
      payor {
        email
        full_name
        phone
        address_line1
        address_line2
        city
        country
        postal_code
        region
        }
      account_code
      reference
      require_payor_address
      security_pin
      settings {
        accepted_payment_methods {
          ach
          card
          cash
        }
      }
      status
      total_paid_amount
    }
  }
}
`

export const get = (invoiceId: String): Promise<GraphQLResult<{invoices: InvoicesWithQuery}>> => {
    const query = {
        query_list: [
            {
                key: "invoice_id",
                value: `%${invoiceId}%`,
                operator: Operator.Like
            }
        ]
    }
    const variables = {
        query: query
    }
    return appSyncQuery(getString, variables)
}

const createInvoiceString = `mutation CreateInvoice($settings: InvoiceSettingsInput,
                                                $currency: String = "USD", 
                                                $due_by: AWSDate, 
                                                $fee_mode: FeeMode!, 
                                                $invoice_amount: Int!, 
                                                $invoice_description: String, 
                                                $invoice_name: String!, 
                                                $invoice_date: AWSDate,
                                                $is_secure: Boolean!, 
                                                $merchant_uid: String!, 
                                                $email: String!, 
                                                $full_name: String!,
                                                $merchant_invoice_number: String,
                                                $account_code: String,
                                                $reference: String,
                                                $require_payor_address: Boolean,
                                                $send_email: Boolean,
                                                $phone: String,
                                                $address_line1: String,
                                                $address_line2: String,
                                                $city: String,
                                                $postal_code: String,
                                                $region: String
                                                ) {
  createInvoice(input: {currency: $currency, 
                        due_by: $due_by, 
                        fee_mode: $fee_mode, 
                        invoice_amount: $invoice_amount, 
                        invoice_description: $invoice_description, 
                        invoice_name: $invoice_name, 
                        invoice_date: $invoice_date,
                        is_secure: $is_secure, 
                        merchant_uid: $merchant_uid, 
                        payor: { 
                            email: $email, 
                            full_name: $full_name, 
                            merchant_uid: $merchant_uid,
                            phone: $phone,
                            address_line1: $address_line1,
                            address_line2: $address_line2,
                            city: $city,
                            postal_code: $postal_code,
                            region: $region
                        }, 
                        settings: $settings, 
                        merchant_invoice_number: $merchant_invoice_number
                        account_code: $account_code,
                        reference: $reference,
                        require_payor_address: $require_payor_address,
                        send_email: $send_email
                        }) {
      merchant_uid
      created_date
      due_by
      fee_mode
      invoice_amount
      invoice_description
      invoice_date
      invoice_id
      invoice_name
      is_secure
      merchant_invoice_number
      payor {
        full_name
        phone
        payor_id
      }
      security_pin
      settings {
        accepted_payment_methods {
          ach
          card
          cash
        }
      }
      account_code
      reference
      require_payor_address
      status
      total_paid_amount
  }
}`

export type CreateInvoiceVariables = {
    settings?: InvoiceSettingsInput,
    due_by?: string,
    fee_mode: FeeMode,
    invoice_amount: number,
    invoice_description?: string,
    invoice_name: string,
    is_secure: boolean,
    merchant_uid: string,
    email: string,
    full_name: string,
    merchant_invoice_number?: string,
    account_code?: string,
    reference?: string,
    require_payor_address?: boolean,
    send_email?: boolean,
    invoice_date?: string,
    phone?: string,
    address_line1?: string,
    address_line2?: string,
    city?: string,
    postal_code?: string,
    region?: string
}

export const createInvoice = (variables: CreateInvoiceVariables): Promise<GraphQLResult<{ createInvoice: Invoice}>> => {
    return appSyncQuery(createInvoiceString, variables)
}

const updateString = `mutation UpdateInvoice($invoice_id: String!, 
                                          $settings: InvoiceSettingsInput, 
                                          $send_email: Boolean, 
                                          $require_payor_address: Boolean, 
                                          $reference: String, 
                                          $merchant_invoice_number: String, 
                                          $is_secure: Boolean, 
                                          $invoice_name: String, 
                                          $invoice_description: String, 
                                          $invoice_date: AWSDate, 
                                          $invoice_amount: Int, 
                                          $fee_mode: FeeMode, 
                                          $due_by: AWSDate, 
                                          $currency: String, 
                                          $account_code: String) {
  updateInvoice(invoice_id: $invoice_id, invoice_update_input: {
                                                account_code: $account_code, 
                                                currency: $currency, 
                                                due_by: $due_by, 
                                                fee_mode: $fee_mode, 
                                                invoice_amount: $invoice_amount, 
                                                invoice_date: $invoice_date, 
                                                invoice_description: $invoice_description, 
                                                invoice_name: $invoice_name, 
                                                is_secure: $is_secure, 
                                                merchant_invoice_number: $merchant_invoice_number, 
                                                reference: $reference, 
                                                require_payor_address: $require_payor_address, 
                                                send_email: $send_email, 
                                                settings: $settings
                                                })
}`

export type UpdateInvoiceVariables = {
    invoice_id: string,
    settings?: InvoiceSettingsInput,
    send_email?: boolean,
    require_payor_address?: boolean,
    reference?: string,
    merchant_invoice_number?: string,
    is_secure?: boolean,
    invoice_name?: string,
    invoice_description?: string,
    invoice_date?: string,
    invoice_amount?: number,
    fee_mode?: FeeMode,
    due_by?: string,
    account_code?: string,
}

export const update = (variables: UpdateInvoiceVariables): Promise<GraphQLResult<{ updateInvoice: Invoice}>> => {
    return appSyncQuery(updateString, variables)
}

const deleteInvoiceString = `mutation DeleteInvoice($invoice_id: String!) {
  deleteInvoice(invoice_id: $invoice_id)
}`

export const deleteInvoice = async (invoiceId: string): Promise<GraphQLResult<{deleteInvoice: boolean}>> => {
    const variables = {
        invoice_id: invoiceId
    }
    return appSyncQuery(deleteInvoiceString, variables)
}

const createOfflinePaymentString = `mutation CreateOfflineTransaction($amount: Int!, $invoice_id: String!, $note: String, $transaction_date: AWSDate!, $type: OfflineTransactionType!) {
  createOfflineTransaction(input: {amount: $amount, invoice_id: $invoice_id, note: $note, transaction_date: $transaction_date, type: $type}) {
    amount
  }
}
`

export const createOfflinePayment = (amount: number, invoiceId: string, note: string, transactionDate: string, type: OfflineTransactionType): Promise<GraphQLResult<{ createOfflineTransaction: OfflineTransaction}>> => {
    const variables = {
        amount: amount,
        invoice_id: invoiceId,
        note: note,
        transaction_date: transactionDate,
        type: type,
    }
    return appSyncQuery(createOfflinePaymentString, variables)
}

const sendEmailString = `mutation SendInvoiceEmail($invoice_id: String!) {
   createInvoiceEmail(invoice_id: $invoice_id)
}
`

export const sendEmail = (invoiceId: string): Promise<GraphQLResult<{createInvoiceEmail: boolean}>> => {
    const variables = {
        invoice_id: invoiceId
    }
    return appSyncQuery(sendEmailString, variables)
}