import {appSyncQuery} from "./index";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import {BackofficeKeyData, BackofficeKeyDataWithKey} from "../GraphQL/internal_types";

const listString = `query ListKeys($merchant_uid: String!) {
  backOfficeKey(merchant_uid: $merchant_uid, entity_level: MERCHANT) {
    created_date
    enabled
    key_name
    last_accessed_date
    merchant_uid
  }
}

`

export const list = (merchantUid: string): Promise<GraphQLResult<{backOfficeKey: Array<BackofficeKeyData>}>> => {
    const variables = {
        merchant_uid: merchantUid
    }
    return appSyncQuery(listString, variables, true)
}

const createString = `mutation CreateSecretKey($key_name: String, $merchant_uid: String!) {
  createBackOfficeKey(entity_level: MERCHANT, merchant_uid: $merchant_uid, key_name: $key_name) {
    api_key
    created_date
    enabled
    key_name
    last_accessed_date
    merchant_uid
  }
}
`

export const createKey = (merchant_uid: string, key_name: string): Promise<GraphQLResult<{createBackOfficeKey: BackofficeKeyDataWithKey}>> => {
    const variables = {
        merchant_uid: merchant_uid,
        key_name: key_name
    }
    return appSyncQuery(createString, variables, true)
}

const deleteString = `mutation DeleteSecretKey($key_name: String!, $merchant_uid: String!) {
  deleteBackOfficeKey(key_name: $key_name, merchant_uid: $merchant_uid)
}
`

export const deleteKey = async (merchant_uid: string, key_name: string): Promise<GraphQLResult<boolean>> => {
    const variables = {
        merchant_uid: merchant_uid,
        key_name: key_name
    }
    return appSyncQuery(deleteString, variables, true)
}

const updateString = `mutation UpdateSecretKey($enabled: Boolean!, $key_name: String!, $merchant_uid: String!) {
  updateBackOfficeKey(key_name: $key_name, merchant_uid: $merchant_uid, enabled: $enabled)
}

`

export const updateKey = (merchant_uid:string, key_name:string, enabled:boolean): Promise<GraphQLResult<boolean>> => {
    const variables = {
        merchant_uid: merchant_uid,
        key_name: key_name,
        enabled: enabled
    }
    return appSyncQuery(updateString, variables, true)
}