import {NotificationContextId, Notification} from "../GraphQL/internal_types";
import {appSyncSubscription} from "./index";

const subscriptionString = `subscription MySubscription($context_id: NotificationContextId!, $merchant_uid: ID!, $primary_value: String, $secondary_value: String) {
  createNotification(merchant_uid: $merchant_uid, context_id: $context_id, primary_value: $primary_value, secondary_value: $secondary_value) {
    context_id
    error
    merchant_uid
    primary_value
    secondary_value
  }
}`

export const subscribe = (contextId: NotificationContextId , merchant_uid: string, onNext: (value: {createNotification: Notification}) => void, onError: (error: any) => void, primaryValue?: string, secondaryValue?: string) => {
    const variables = {
        context_id: contextId,
        merchant_uid: merchant_uid,
        primary_value: primaryValue,
        secondary_value: secondaryValue
    }
    return appSyncSubscription(subscriptionString, variables, onNext, onError, true)
}