export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type AcceptedPaymentMethods = {
  __typename?: 'AcceptedPaymentMethods';
  ach?: Maybe<Scalars['Boolean']>;
  card?: Maybe<Scalars['Boolean']>;
  cash?: Maybe<Scalars['Boolean']>;
};

export enum AcceptedPaymentMethodsEnum {
  All = 'ALL',
  NotAch = 'NOT_ACH',
  NotCard = 'NOT_CARD',
  NotCash = 'NOT_CASH',
  OnlyAch = 'ONLY_ACH',
  OnlyCard = 'ONLY_CARD',
  OnlyCash = 'ONLY_CASH'
}

export type AcceptedPaymentMethodsInput = {
  ach?: InputMaybe<Scalars['Boolean']>;
  card?: InputMaybe<Scalars['Boolean']>;
  cash?: InputMaybe<Scalars['Boolean']>;
};

export enum AuthProvider {
  ApiKey = 'apiKey',
  Iam = 'iam',
  Oidc = 'oidc',
  UserPools = 'userPools'
}

export type AuthRule = {
  allow: AuthStrategy;
  groupClaim?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupsField?: InputMaybe<Scalars['String']>;
  identityClaim?: InputMaybe<Scalars['String']>;
  mutations?: InputMaybe<Array<InputMaybe<ModelMutation>>>;
  operations?: InputMaybe<Array<InputMaybe<ModelOperation>>>;
  ownerField?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<AuthProvider>;
  queries?: InputMaybe<Array<InputMaybe<ModelQuery>>>;
};

export enum AuthStrategy {
  Groups = 'groups',
  Owner = 'owner',
  Private = 'private',
  Public = 'public'
}

export enum CallToActionType {
  Book = 'BOOK',
  Donate = 'DONATE',
  Pay = 'PAY'
}

export enum ConjunctiveOperator {
  AndNext = 'AND_NEXT',
  NoneNext = 'NONE_NEXT',
  OrNext = 'OR_NEXT'
}

export type Device = {
  __typename?: 'Device';
  device_description?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  merchant_uid?: Maybe<Scalars['String']>;
};

export type Devices = {
  __typename?: 'Devices';
  items?: Maybe<Array<Maybe<Device>>>;
  total_row_count?: Maybe<Scalars['Int']>;
};

export type Dispute = {
  __typename?: 'Dispute';
  account_code?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  card_brand?: Maybe<Scalars['String']>;
  dispute_date?: Maybe<Scalars['AWSDateTime']>;
  dispute_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  evidence_last_send_date?: Maybe<Scalars['AWSDateTime']>;
  expiration_date?: Maybe<Scalars['AWSDateTime']>;
  full_name?: Maybe<Scalars['String']>;
  last_four?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  reason?: Maybe<DisputeReason>;
  reason_message?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  settlement_batch?: Maybe<Scalars['String']>;
  settlement_deposit_batch?: Maybe<Scalars['String']>;
  settlement_withdrawal_batch?: Maybe<Scalars['String']>;
  status?: Maybe<DisputeStatus>;
  transaction_date?: Maybe<Scalars['AWSDateTime']>;
  transaction_id?: Maybe<Scalars['String']>;
  updated_date?: Maybe<Scalars['AWSDateTime']>;
};

export enum DisputeReason {
  Clerical = 'CLERICAL',
  Fraud = 'FRAUD',
  Inquiry = 'INQUIRY',
  Quality = 'QUALITY',
  Technical = 'TECHNICAL'
}

export enum DisputeStatus {
  Inquiry = 'INQUIRY',
  Lost = 'LOST',
  Pending = 'PENDING',
  Won = 'WON'
}

export type Disputes = {
  __typename?: 'Disputes';
  items?: Maybe<Array<Maybe<Dispute>>>;
  total_row_count?: Maybe<Scalars['Int']>;
};

export enum FeeMode {
  Interchange = 'INTERCHANGE',
  MerchantFee = 'MERCHANT_FEE',
  ServiceFee = 'SERVICE_FEE'
}

export type FeeModel = {
  __typename?: 'FeeModel';
  interchange_plus?: Maybe<Scalars['Boolean']>;
  merchant_fee?: Maybe<FeeModelDetail>;
  service_fee?: Maybe<FeeModelDetail>;
  service_fee_min?: Maybe<FeeModelDetail>;
};

export type FeeModelDetail = {
  __typename?: 'FeeModelDetail';
  ach_basis?: Maybe<Scalars['Int']>;
  ach_fixed?: Maybe<Scalars['Int']>;
  card_basis?: Maybe<Scalars['Int']>;
  card_fixed?: Maybe<Scalars['Int']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  account_code?: Maybe<Scalars['String']>;
  api_key?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<Scalars['String']>;
  due_by?: Maybe<Scalars['AWSDate']>;
  fee_mode?: Maybe<FeeMode>;
  invoice_amount?: Maybe<Scalars['Int']>;
  invoice_date?: Maybe<Scalars['AWSDate']>;
  invoice_description?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['String']>;
  invoice_name?: Maybe<Scalars['String']>;
  is_secure?: Maybe<Scalars['Boolean']>;
  merchant_invoice_number?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  offline_transactions?: Maybe<Array<Maybe<OfflineTransaction>>>;
  payor?: Maybe<Payor>;
  reference?: Maybe<Scalars['String']>;
  require_payor_address?: Maybe<Scalars['Boolean']>;
  security_pin?: Maybe<Scalars['String']>;
  settings?: Maybe<InvoiceSettings>;
  status?: Maybe<InvoiceStatus>;
  total_paid_amount?: Maybe<Scalars['Int']>;
};

export type InvoiceInput = {
  account_code?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  due_by?: InputMaybe<Scalars['AWSDate']>;
  fee_mode?: InputMaybe<FeeMode>;
  invoice_amount: Scalars['Int'];
  invoice_date?: InputMaybe<Scalars['AWSDate']>;
  invoice_description?: InputMaybe<Scalars['String']>;
  invoice_name?: InputMaybe<Scalars['String']>;
  is_secure?: InputMaybe<Scalars['Boolean']>;
  merchant_invoice_number?: InputMaybe<Scalars['String']>;
  merchant_uid: Scalars['String'];
  payor?: InputMaybe<PayorInput>;
  payor_id?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  require_payor_address?: InputMaybe<Scalars['Boolean']>;
  send_email?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<InvoiceSettingsInput>;
};

export type InvoiceSettings = {
  __typename?: 'InvoiceSettings';
  accepted_payment_methods?: Maybe<AcceptedPaymentMethods>;
};

export type InvoiceSettingsInput = {
  accepted_payment_methods?: InputMaybe<AcceptedPaymentMethodsInput>;
};

export enum InvoiceStatus {
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID'
}

export type InvoiceUpdateInput = {
  account_code?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  due_by?: InputMaybe<Scalars['AWSDate']>;
  fee_mode?: InputMaybe<FeeMode>;
  invoice_amount?: InputMaybe<Scalars['Int']>;
  invoice_date?: InputMaybe<Scalars['AWSDate']>;
  invoice_description?: InputMaybe<Scalars['String']>;
  invoice_name?: InputMaybe<Scalars['String']>;
  is_secure?: InputMaybe<Scalars['Boolean']>;
  merchant_invoice_number?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  require_payor_address?: InputMaybe<Scalars['Boolean']>;
  send_email?: InputMaybe<Scalars['Boolean']>;
  settings?: InputMaybe<InvoiceSettingsInput>;
};

export type InvoiceWithQuery = {
  __typename?: 'InvoiceWithQuery';
  account_code?: Maybe<Scalars['String']>;
  api_key?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<Scalars['String']>;
  due_by?: Maybe<Scalars['AWSDate']>;
  fee_mode?: Maybe<FeeMode>;
  invoice_amount?: Maybe<Scalars['Int']>;
  invoice_date?: Maybe<Scalars['AWSDate']>;
  invoice_description?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['String']>;
  invoice_name?: Maybe<Scalars['String']>;
  is_secure?: Maybe<Scalars['Boolean']>;
  merchant_invoice_number?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  offline_transactions?: Maybe<Array<Maybe<OfflineTransaction>>>;
  payor?: Maybe<Payor>;
  reference?: Maybe<Scalars['String']>;
  require_payor_address?: Maybe<Scalars['Boolean']>;
  security_pin?: Maybe<Scalars['String']>;
  settings?: Maybe<InvoiceSettings>;
  status?: Maybe<InvoiceStatus>;
  total_paid_amount?: Maybe<Scalars['Int']>;
};


export type InvoiceWithQueryPayorArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};

export type InvoicesWithQuery = {
  __typename?: 'InvoicesWithQuery';
  items?: Maybe<Array<Maybe<InvoiceWithQuery>>>;
  total_row_count?: Maybe<Scalars['Int']>;
};

export type ListMerchant = {
  __typename?: 'ListMerchant';
  ach_active?: Maybe<Scalars['Boolean']>;
  card_active?: Maybe<Scalars['Boolean']>;
  cash_active?: Maybe<Scalars['Boolean']>;
  is_system?: Maybe<Scalars['Boolean']>;
  merchant_name?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  parent_merchant_uid?: Maybe<Scalars['String']>;
  submitted_onboarding?: Maybe<Scalars['Boolean']>;
};

export type Merchant = {
  __typename?: 'Merchant';
  ach_active?: Maybe<Scalars['Boolean']>;
  api_key?: Maybe<Scalars['String']>;
  card_active?: Maybe<Scalars['Boolean']>;
  cash_active?: Maybe<Scalars['Boolean']>;
  fee_model?: Maybe<FeeModel>;
  is_system?: Maybe<Scalars['Boolean']>;
  merchant_name?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  parent_merchant_uid?: Maybe<Scalars['String']>;
  settings?: Maybe<MerchantSettings>;
  submitted_onboarding?: Maybe<Scalars['Boolean']>;
};

export type MerchantSettings = {
  __typename?: 'MerchantSettings';
  contact_email?: Maybe<Scalars['AWSEmail']>;
  contact_phone?: Maybe<Scalars['AWSPhone']>;
  facebook?: Maybe<Scalars['AWSURL']>;
  instagram?: Maybe<Scalars['AWSURL']>;
  linkedin?: Maybe<Scalars['AWSURL']>;
  tiktok?: Maybe<Scalars['AWSURL']>;
  twitter?: Maybe<Scalars['AWSURL']>;
  website?: Maybe<Scalars['AWSURL']>;
};

export type MerchantSettingsInput = {
  contact_email?: InputMaybe<Scalars['AWSEmail']>;
  contact_phone?: InputMaybe<Scalars['AWSPhone']>;
  facebook?: InputMaybe<Scalars['AWSURL']>;
  instagram?: InputMaybe<Scalars['AWSURL']>;
  linkedin?: InputMaybe<Scalars['AWSURL']>;
  tiktok?: InputMaybe<Scalars['AWSURL']>;
  twitter?: InputMaybe<Scalars['AWSURL']>;
  website?: InputMaybe<Scalars['AWSURL']>;
};

export type MerchantUserInput = {
  email: Scalars['AWSEmail'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone?: InputMaybe<Scalars['AWSPhone']>;
};

export type Merchants = {
  __typename?: 'Merchants';
  items?: Maybe<Array<Maybe<ListMerchant>>>;
  total_row_count?: Maybe<Scalars['Int']>;
};

export type Metadata = {
  __typename?: 'Metadata';
  id?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  metadata_associate?: Maybe<MetadataAssociate>;
  metadata_associate_id?: Maybe<Scalars['String']>;
  metadata_key?: Maybe<Scalars['String']>;
  metadata_type?: Maybe<MetadataType>;
  metadata_value?: Maybe<Scalars['String']>;
  updated_row_at?: Maybe<Scalars['AWSDateTime']>;
};

export enum MetadataAssociate {
  Invoice = 'INVOICE',
  PaymentIntent = 'PAYMENT_INTENT',
  PaymentMethodToken = 'PAYMENT_METHOD_TOKEN',
  PaymentSession = 'PAYMENT_SESSION',
  Recurring = 'RECURRING',
  Transaction = 'TRANSACTION'
}

export type MetadataCore = {
  __typename?: 'MetadataCore';
  metadata: Scalars['AWSJSON'];
};

export enum MetadataType {
  Boolean = 'BOOLEAN',
  Datetime = 'DATETIME',
  Int = 'INT',
  String = 'STRING'
}

export type MissedRecurringPaymentData = {
  __typename?: 'MissedRecurringPaymentData';
  fee?: Maybe<Scalars['Int']>;
  number_of_payments_missed?: Maybe<Scalars['Int']>;
  total_amount_owed?: Maybe<Scalars['Int']>;
};

export enum ModelMutation {
  Create = 'create',
  Delete = 'delete',
  Update = 'update'
}

export type ModelMutationMap = {
  create?: InputMaybe<Scalars['String']>;
  delete?: InputMaybe<Scalars['String']>;
  update?: InputMaybe<Scalars['String']>;
};

export enum ModelOperation {
  Create = 'create',
  Delete = 'delete',
  Read = 'read',
  Update = 'update'
}

export enum ModelQuery {
  Get = 'get',
  List = 'list'
}

export type ModelQueryMap = {
  get?: InputMaybe<Scalars['String']>;
  list?: InputMaybe<Scalars['String']>;
};

export enum ModelSubscriptionLevel {
  Off = 'off',
  On = 'on',
  Public = 'public'
}

export type ModelSubscriptionMap = {
  level?: InputMaybe<ModelSubscriptionLevel>;
  onCreate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  onDelete?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  onUpdate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export enum MoveDirection {
  Backward = 'BACKWARD',
  Forward = 'FORWARD'
}

export type Mutation = {
  __typename?: 'Mutation';
  cancelRecurringPayment?: Maybe<Scalars['Boolean']>;
  createInvoice?: Maybe<Invoice>;
  createInvoiceEmail?: Maybe<Scalars['Boolean']>;
  createMerchant?: Maybe<ListMerchant>;
  createOfflineTransaction?: Maybe<OfflineTransaction>;
  createOneTimePayment?: Maybe<OneTimePayment>;
  createPaymentLink?: Maybe<PaymentLink>;
  createPaymentMethodToken?: Maybe<PaymentMethodToken>;
  createPayor?: Maybe<Payor>;
  createRecurringPayment?: Maybe<RecurringPayment>;
  createRefund?: Maybe<Scalars['Boolean']>;
  createRetryForFailedRecurringPayment?: Maybe<Scalars['Boolean']>;
  createUser?: Maybe<User>;
  createUserPasswordReset?: Maybe<Scalars['Boolean']>;
  createVoidForRefund?: Maybe<Scalars['Boolean']>;
  deleteInvoice?: Maybe<Scalars['Boolean']>;
  deleteMetadata?: Maybe<Scalars['Boolean']>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  updateDevice?: Maybe<Scalars['Boolean']>;
  updateInvoice?: Maybe<Scalars['Boolean']>;
  updateMerchantSettings?: Maybe<Scalars['Boolean']>;
  updateMetadata?: Maybe<Scalars['Boolean']>;
  updatePaymentLink?: Maybe<PaymentLink>;
  updatePayor?: Maybe<Scalars['Boolean']>;
  updateRecurringPayment?: Maybe<RecurringPayment>;
};


export type MutationCancelRecurringPaymentArgs = {
  recurring_id: Scalars['String'];
};


export type MutationCreateInvoiceArgs = {
  input: InvoiceInput;
};


export type MutationCreateInvoiceEmailArgs = {
  invoice_id: Scalars['String'];
};


export type MutationCreateMerchantArgs = {
  merchant_name: Scalars['String'];
  parent_merchant_uid?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<MerchantUserInput>;
};


export type MutationCreateOfflineTransactionArgs = {
  input: OfflineTransactionInput;
};


export type MutationCreateOneTimePaymentArgs = {
  account_code?: InputMaybe<Scalars['String']>;
  amount: Scalars['Int'];
  fee?: InputMaybe<Scalars['Int']>;
  fee_mode?: InputMaybe<FeeMode>;
  invoice_id?: InputMaybe<Scalars['String']>;
  merchant_uid: Scalars['String'];
  metadata?: InputMaybe<Scalars['AWSJSON']>;
  payment_method_id: Scalars['String'];
  payment_parameters_name?: InputMaybe<Scalars['String']>;
  receipt_description?: InputMaybe<Scalars['String']>;
  recurring_id?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  send_receipt?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreatePaymentLinkArgs = {
  input: PaymentLinkInput;
};


export type MutationCreatePaymentMethodTokenArgs = {
  input: PaymentMethodTokenInput;
};


export type MutationCreatePayorArgs = {
  input: PayorInput;
};


export type MutationCreateRecurringPaymentArgs = {
  input: RecurringPaymentInput;
};


export type MutationCreateRefundArgs = {
  amount: Scalars['Int'];
  refund_email?: InputMaybe<Scalars['String']>;
  refund_reason: RefundReasonInput;
  transaction_id: Scalars['String'];
};


export type MutationCreateRetryForFailedRecurringPaymentArgs = {
  recurring_id: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateUserPasswordResetArgs = {
  user_pool: UserPool;
  username: Scalars['String'];
};


export type MutationCreateVoidForRefundArgs = {
  transaction_id: Scalars['String'];
};


export type MutationDeleteInvoiceArgs = {
  invoice_id: Scalars['String'];
};


export type MutationDeleteMetadataArgs = {
  id: Scalars['String'];
  merchant_uid: Scalars['String'];
  metadata_associate: MetadataAssociate;
  metadata_keys: Array<InputMaybe<Scalars['String']>>;
};


export type MutationDeleteUserArgs = {
  user_pool: UserPool;
  username: Scalars['String'];
};


export type MutationUpdateDeviceArgs = {
  device_description: Scalars['String'];
  device_id: Scalars['String'];
  merchant_uid: Scalars['String'];
};


export type MutationUpdateInvoiceArgs = {
  invoice_id: Scalars['String'];
  invoice_update_input: InvoiceUpdateInput;
};


export type MutationUpdateMerchantSettingsArgs = {
  merchant_uid: Scalars['ID'];
  settings: MerchantSettingsInput;
};


export type MutationUpdateMetadataArgs = {
  id: Scalars['String'];
  merchant_uid: Scalars['String'];
  metadata: Scalars['AWSJSON'];
  metadata_associate: MetadataAssociate;
};


export type MutationUpdatePaymentLinkArgs = {
  input: UpdatePaymentLinkInput;
};


export type MutationUpdatePayorArgs = {
  payor_data: PayorData;
  payor_id: Scalars['String'];
};


export type MutationUpdateRecurringPaymentArgs = {
  input: UpdateRecurringPaymentInput;
};

export type OfflineTransaction = {
  __typename?: 'OfflineTransaction';
  amount?: Maybe<Scalars['Int']>;
  instance_id?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  transaction_date?: Maybe<Scalars['AWSDate']>;
  type?: Maybe<OfflineTransactionType>;
};

export type OfflineTransactionInput = {
  amount: Scalars['Int'];
  invoice_id: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  transaction_date: Scalars['AWSDate'];
  type: OfflineTransactionType;
};

export enum OfflineTransactionType {
  Ach = 'ACH',
  Card = 'CARD',
  Cash = 'CASH',
  Other = 'OTHER'
}

export type OneTimePayment = {
  __typename?: 'OneTimePayment';
  amount?: Maybe<Scalars['Int']>;
  card_brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<Scalars['String']>;
  failure_reason?: Maybe<Scalars['String']>;
  last_four?: Maybe<Scalars['String']>;
  service_fee?: Maybe<Scalars['Int']>;
  status?: Maybe<TransactionStatus>;
  transaction_id?: Maybe<Scalars['String']>;
};

export enum Operator {
  Equal = 'EQUAL',
  EqualFalse = 'EQUAL_FALSE',
  EqualTrue = 'EQUAL_TRUE',
  GreaterEqual = 'GREATER_EQUAL',
  GreaterThan = 'GREATER_THAN',
  InList = 'IN_LIST',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  LessEqual = 'LESS_EQUAL',
  LessThan = 'LESS_THAN',
  Like = 'LIKE',
  NotEqual = 'NOT_EQUAL',
  NotInList = 'NOT_IN_LIST',
  NotLike = 'NOT_LIKE'
}

export type PaymentLink = {
  __typename?: 'PaymentLink';
  accepted_payment_methods?: Maybe<AcceptedPaymentMethodsEnum>;
  account_code?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  amount_is_variable?: Maybe<Scalars['Boolean']>;
  call_to_action?: Maybe<CallToActionType>;
  created_date?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<Scalars['String']>;
  custom_success_message?: Maybe<Scalars['String']>;
  fee_mode?: Maybe<FeeMode>;
  is_active?: Maybe<Scalars['Boolean']>;
  link_id?: Maybe<Scalars['String']>;
  link_name?: Maybe<Scalars['String']>;
  link_url?: Maybe<Scalars['String']>;
  max_amount?: Maybe<Scalars['Int']>;
  merchant_uid?: Maybe<Scalars['String']>;
  min_amount?: Maybe<Scalars['Int']>;
  payment_description?: Maybe<Scalars['String']>;
  payment_name?: Maybe<Scalars['String']>;
  redirect_url?: Maybe<Scalars['String']>;
  require_phone?: Maybe<Scalars['Boolean']>;
};

export type PaymentLinkInput = {
  accepted_payment_methods?: InputMaybe<AcceptedPaymentMethodsEnum>;
  account_code?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Int']>;
  amount_is_variable?: InputMaybe<Scalars['Boolean']>;
  call_to_action?: InputMaybe<CallToActionType>;
  currency?: InputMaybe<Scalars['String']>;
  custom_success_message?: InputMaybe<Scalars['String']>;
  fee_mode?: InputMaybe<FeeMode>;
  link_name: Scalars['String'];
  max_amount?: InputMaybe<Scalars['Int']>;
  merchant_uid: Scalars['String'];
  min_amount?: InputMaybe<Scalars['Int']>;
  payment_description?: InputMaybe<Scalars['String']>;
  payment_name: Scalars['String'];
  redirect_url?: InputMaybe<Scalars['String']>;
  require_phone?: InputMaybe<Scalars['Boolean']>;
};

export type PaymentLinks = {
  __typename?: 'PaymentLinks';
  items?: Maybe<Array<Maybe<PaymentLink>>>;
  total_row_count?: Maybe<Scalars['Int']>;
};

export type PaymentMethodToken = {
  __typename?: 'PaymentMethodToken';
  address_line1?: Maybe<Scalars['String']>;
  address_line2?: Maybe<Scalars['String']>;
  card_brand?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  exp_date?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_four?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['String']>;
  payment_type?: Maybe<PaymentType>;
  payor?: Maybe<Payor>;
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type PaymentMethodTokenInput = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  card_brand?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  exp_date?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_four: Scalars['String'];
  merchant_uid?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  payment_type: PaymentType;
  payor?: InputMaybe<PayorInput>;
  payor_id?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  processor: Scalars['String'];
  processor_token: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
};

export type PaymentMethodTokenWithQuery = {
  __typename?: 'PaymentMethodTokenWithQuery';
  address_line1?: Maybe<Scalars['String']>;
  address_line2?: Maybe<Scalars['String']>;
  card_brand?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  exp_date?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_four?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['String']>;
  payment_type?: Maybe<PaymentType>;
  payor?: Maybe<Payor>;
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};


export type PaymentMethodTokenWithQueryPayorArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};

export type PaymentMethodTokensWithQuery = {
  __typename?: 'PaymentMethodTokensWithQuery';
  items?: Maybe<Array<Maybe<PaymentMethodTokenWithQuery>>>;
  total_row_count?: Maybe<Scalars['Int']>;
};

export enum PaymentType {
  Ach = 'ACH',
  Card = 'CARD',
  Cash = 'CASH'
}

export type Payor = {
  __typename?: 'Payor';
  address_line1?: Maybe<Scalars['String']>;
  address_line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  payor_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type PayorData = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type PayorInput = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  merchant_uid?: InputMaybe<Scalars['String']>;
  payor_id?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type Payors = {
  __typename?: 'Payors';
  items?: Maybe<Array<Maybe<Payor>>>;
  total_row_count?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  devices?: Maybe<Devices>;
  disputes?: Maybe<Disputes>;
  disputesForBatch?: Maybe<Disputes>;
  invoices?: Maybe<InvoicesWithQuery>;
  merchant?: Maybe<Merchant>;
  merchants?: Maybe<Merchants>;
  missedRecurringPaymentData?: Maybe<MissedRecurringPaymentData>;
  paymentLinks?: Maybe<PaymentLinks>;
  paymentMethodTokens?: Maybe<PaymentMethodTokensWithQuery>;
  payors?: Maybe<Payors>;
  recurringPayments?: Maybe<RecurringPaymentsWithQuery>;
  serviceFeeAmount?: Maybe<ServiceFeeAmount>;
  settlements?: Maybe<Settlements>;
  transactions?: Maybe<Transactions>;
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryDevicesArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
  offset_id?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryDisputesArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
  offset_id?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryDisputesForBatchArgs = {
  merchant_uid: Scalars['String'];
  settlement_batch: Scalars['Int'];
};


export type QueryInvoicesArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
  offset_id?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryMerchantArgs = {
  merchant_name?: InputMaybe<Scalars['String']>;
  merchant_uid?: InputMaybe<Scalars['String']>;
};


export type QueryMerchantsArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
  offset_id?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryMissedRecurringPaymentDataArgs = {
  recurring_id: Scalars['String'];
};


export type QueryPaymentLinksArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
  offset_id?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryPaymentMethodTokensArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
  offset_id?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryPayorsArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
  offset_id?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryRecurringPaymentsArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
  offset_id?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryServiceFeeAmountArgs = {
  amount: Scalars['Int'];
  merchant_uid: Scalars['String'];
};


export type QuerySettlementsArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
  offset_id?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryTransactionsArgs = {
  direction?: InputMaybe<MoveDirection>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['String']>;
  offset_id?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<SqlQuery>;
};


export type QueryUsersArgs = {
  merchant_uid?: InputMaybe<Scalars['String']>;
  user_pool: UserPool;
};

export type QueryPair = {
  conjunctive_operator?: InputMaybe<ConjunctiveOperator>;
  in_values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  key?: InputMaybe<Scalars['String']>;
  operator?: InputMaybe<Operator>;
  query_group?: InputMaybe<Array<InputMaybe<QueryPair>>>;
  value?: InputMaybe<Scalars['String']>;
};

export enum RecurringInterval {
  Annual = 'ANNUAL',
  BiAnnual = 'BI_ANNUAL',
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY'
}

export type RecurringPayment = {
  __typename?: 'RecurringPayment';
  account_code?: Maybe<Scalars['String']>;
  amount_per_payment?: Maybe<Scalars['Int']>;
  created_date?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<Scalars['String']>;
  fee_mode?: Maybe<FeeMode>;
  fee_per_payment?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_processing?: Maybe<Scalars['Boolean']>;
  merchant_uid?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['AWSJSON']>;
  mute_all_emails?: Maybe<Scalars['Boolean']>;
  next_payment_date?: Maybe<Scalars['AWSDate']>;
  payment_interval?: Maybe<RecurringInterval>;
  payment_method?: Maybe<PaymentMethodToken>;
  payor?: Maybe<Payor>;
  prev_payment_date?: Maybe<Scalars['AWSDate']>;
  recurring_description?: Maybe<Scalars['String']>;
  recurring_id?: Maybe<Scalars['String']>;
  recurring_name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  remaining_payments?: Maybe<Scalars['Int']>;
  status?: Maybe<RecurringStatus>;
  total_amount_per_payment?: Maybe<Scalars['Int']>;
};

export type RecurringPaymentInput = {
  account_code?: InputMaybe<Scalars['String']>;
  amount: Scalars['Int'];
  fee_mode?: InputMaybe<FeeMode>;
  first_payment_date?: InputMaybe<Scalars['AWSDate']>;
  merchant_uid: Scalars['String'];
  metadata?: InputMaybe<Scalars['AWSJSON']>;
  mute_all_emails?: InputMaybe<Scalars['Boolean']>;
  payment_count?: InputMaybe<Scalars['Int']>;
  payment_interval: RecurringInterval;
  payment_method_id: Scalars['String'];
  payor?: InputMaybe<PayorInput>;
  payor_id?: InputMaybe<Scalars['String']>;
  recurring_description?: InputMaybe<Scalars['String']>;
  recurring_name: Scalars['String'];
  reference?: InputMaybe<Scalars['String']>;
};

export type RecurringPaymentWithQuery = {
  __typename?: 'RecurringPaymentWithQuery';
  account_code?: Maybe<Scalars['String']>;
  amount_per_payment?: Maybe<Scalars['Int']>;
  created_date?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<Scalars['String']>;
  fee_mode?: Maybe<FeeMode>;
  fee_per_payment?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_processing?: Maybe<Scalars['Boolean']>;
  merchant_uid?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['AWSJSON']>;
  mute_all_emails?: Maybe<Scalars['Boolean']>;
  next_payment_date?: Maybe<Scalars['AWSDate']>;
  payment_interval?: Maybe<RecurringInterval>;
  payment_method?: Maybe<PaymentMethodTokenWithQuery>;
  payor?: Maybe<Payor>;
  prev_payment_date?: Maybe<Scalars['AWSDate']>;
  recurring_description?: Maybe<Scalars['String']>;
  recurring_id?: Maybe<Scalars['String']>;
  recurring_name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  remaining_payments?: Maybe<Scalars['Int']>;
  status?: Maybe<RecurringStatus>;
  total_amount_per_payment?: Maybe<Scalars['Int']>;
};


export type RecurringPaymentWithQueryMetadataArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};


export type RecurringPaymentWithQueryPayment_MethodArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};


export type RecurringPaymentWithQueryPayorArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};

export type RecurringPaymentsWithQuery = {
  __typename?: 'RecurringPaymentsWithQuery';
  items?: Maybe<Array<Maybe<RecurringPaymentWithQuery>>>;
  total_row_count?: Maybe<Scalars['Int']>;
};

export enum RecurringStatus {
  InstrumentFailure = 'INSTRUMENT_FAILURE',
  Success = 'SUCCESS',
  SystemFailure = 'SYSTEM_FAILURE'
}

export type RefundReason = {
  __typename?: 'RefundReason';
  reason_code?: Maybe<RefundReasonCode>;
  reason_details?: Maybe<Scalars['String']>;
};

export enum RefundReasonCode {
  Duplicate = 'DUPLICATE',
  Fraudulent = 'FRAUDULENT',
  Other = 'OTHER',
  RequestedByCustomer = 'REQUESTED_BY_CUSTOMER'
}

export type RefundReasonInput = {
  reason_code: RefundReasonCode;
  reason_details?: InputMaybe<Scalars['String']>;
};

export type ServiceFeeAmount = {
  __typename?: 'ServiceFeeAmount';
  ach?: Maybe<ServiceFeeCalculation>;
  card?: Maybe<ServiceFeeCalculation>;
};

export type ServiceFeeCalculation = {
  __typename?: 'ServiceFeeCalculation';
  adjusted_total?: Maybe<Scalars['Int']>;
  fee?: Maybe<Scalars['Int']>;
  fee_limit_reached?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['Int']>;
};

export type Settlement = {
  __typename?: 'Settlement';
  currency?: Maybe<Scalars['String']>;
  gross_amount?: Maybe<Scalars['Int']>;
  merchant_uid?: Maybe<Scalars['String']>;
  net_amount?: Maybe<Scalars['Int']>;
  settlement_batch?: Maybe<Scalars['Int']>;
  settlement_date?: Maybe<Scalars['AWSDateTime']>;
  status?: Maybe<Scalars['String']>;
  total_adjustments?: Maybe<Scalars['Int']>;
  total_fees?: Maybe<Scalars['Int']>;
  transaction_debit_count?: Maybe<Scalars['Int']>;
  transaction_dispute_count?: Maybe<Scalars['Int']>;
  transaction_reversal_count?: Maybe<Scalars['Int']>;
};

export enum SettlementStatus {
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export type Settlements = {
  __typename?: 'Settlements';
  items?: Maybe<Array<Maybe<Settlement>>>;
  total_row_count?: Maybe<Scalars['Int']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SortPair = {
  direction?: InputMaybe<SortDirection>;
  key?: InputMaybe<Scalars['String']>;
};

export type SqlQuery = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
  sort_list?: InputMaybe<Array<InputMaybe<SortPair>>>;
};

export type TimestampConfiguration = {
  createdAt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  account_code?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  dispute_status?: Maybe<DisputeStatus>;
  failure_reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  fee_mode?: Maybe<FeeMode>;
  fees?: Maybe<Scalars['Int']>;
  gross_amount?: Maybe<Scalars['Int']>;
  invoice?: Maybe<Invoice>;
  is_settled?: Maybe<Scalars['Boolean']>;
  merchant_uid?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['AWSJSON']>;
  net_amount?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<PaymentMethodTokenWithQuery>;
  recurring?: Maybe<RecurringPayment>;
  reference?: Maybe<Scalars['String']>;
  refund_reason?: Maybe<RefundReason>;
  refund_voidable?: Maybe<Scalars['Boolean']>;
  refunded_amount?: Maybe<Scalars['Int']>;
  settlement_batch?: Maybe<Scalars['Int']>;
  status?: Maybe<TransactionStatus>;
  timezone?: Maybe<Scalars['String']>;
  transaction_date?: Maybe<Scalars['AWSDateTime']>;
  transaction_id?: Maybe<Scalars['String']>;
  transaction_type?: Maybe<TransactionType>;
};


export type TransactionMetadataArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};


export type TransactionPayment_MethodArgs = {
  query_list?: InputMaybe<Array<InputMaybe<QueryPair>>>;
};

export enum TransactionStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Settled = 'SETTLED',
  Succeeded = 'SUCCEEDED',
  Voided = 'VOIDED'
}

export enum TransactionType {
  Debit = 'DEBIT',
  Failure = 'FAILURE',
  Reversal = 'REVERSAL'
}

export type Transactions = {
  __typename?: 'Transactions';
  items?: Maybe<Array<Maybe<Transaction>>>;
  total_row_count?: Maybe<Scalars['Int']>;
};

export type UpdatePaymentLinkInput = {
  accepted_payment_methods?: InputMaybe<AcceptedPaymentMethodsEnum>;
  account_code?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Int']>;
  amount_is_variable?: InputMaybe<Scalars['Boolean']>;
  call_to_action?: InputMaybe<CallToActionType>;
  currency?: InputMaybe<Scalars['String']>;
  custom_success_message?: InputMaybe<Scalars['String']>;
  fee_mode?: InputMaybe<FeeMode>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  link_id: Scalars['String'];
  link_name?: InputMaybe<Scalars['String']>;
  max_amount?: InputMaybe<Scalars['Int']>;
  merchant_uid: Scalars['String'];
  min_amount?: InputMaybe<Scalars['Int']>;
  payment_description?: InputMaybe<Scalars['String']>;
  payment_name?: InputMaybe<Scalars['String']>;
  redirect_url?: InputMaybe<Scalars['String']>;
  require_phone?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateRecurringPaymentInput = {
  mute_all_emails?: InputMaybe<Scalars['Boolean']>;
  pay_all_missed_payments?: InputMaybe<Scalars['Boolean']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  recurring_id: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  user_status?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UserInput = {
  email: Scalars['AWSEmail'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  merchant_uid?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['AWSPhone']>;
  user_pool: UserPool;
};

export enum UserPool {
  Merchant = 'MERCHANT',
  Partner = 'PARTNER',
  System = 'SYSTEM'
}
