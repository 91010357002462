/* global FreshworksWidget */
import React from "react";
import {MerchantOnboarding} from "../../GraphQL/internal_types";
import {Button} from "@paytheory/components.common.button";
import {DateValue, parseDate} from '@internationalized/date';
import * as Z from "zod";
import {ZodError, ZodSchema} from "zod";
import {validatePhone, validatePostalCode, validPastDateWithinRange} from "@paytheory/utility.validators";
import {convertEmptyStringsToNull, safeParseInt} from "../../util"
// @ts-ignore
import {v4 as uuidv4} from 'uuid';
import {formatDollarInputValue, preparePhoneForAWS} from "@paytheory/utility.formatters";

export const convertDateToString = (date: DateValue | null): string => {
    if (date) {
        const month = date.month < 10 ? `0${date.month}` : date.month;
        const day = date.day < 10 ? `0${date.day}` : date.day;
        // Ensure that it is a 4 digit year
        const year = `${date.year}`.padStart(4, "0")
        return `${year}-${month}-${day}`;
    } else {
        return "";
    }
}

export const PTTOS_LINK = "https://www.paytheory.com/privacy-and-terms/terms-of-service"

export const schoolMCCs = ["8211", "8220", "8241", "8244", "8249", "8299"]

export const initializeDateFromString = (date: string | undefined | null): DateValue | null => {
    if (date) {
        try {
            return parseDate(date);
        } catch {
            return null;
        }
    } else {
        return null;
    }
}

export type ValidationErrors<T> = Partial<Record<keyof T, string>>
export const validateAction = <T extends {}>(body: { [key: string]: any }, schema: ZodSchema): { valid: boolean, errors: ValidationErrors<T> } => {
    try {
        schema.parse(body);
        return {valid: true, errors: {}};
    } catch (e) {
        type InputErrors = ValidationErrors<Z.infer<typeof schema>>
        let errors = e as ZodError
        const errorMap = errors.issues.reduce((acc: InputErrors, error) => {
            const key = error.path[0] as keyof Z.infer<typeof schema>
            acc[key] = error.message
            return acc
        }, {})
        return {valid: false, errors: errorMap}
    }
}

export const phoneString = Z.string().refine((value) => {
    return validatePhone(value);
});

export const postalCodeString = Z.string().refine((value) => {
    return validatePostalCode(value);
});

export const birthdayString = Z.string().refine((value) => {
    return validPastDateWithinRange(value, 120);
}, "Invalid date of birth");

export const incorporationDateString = Z.string().refine((value) => {
    return validPastDateWithinRange(value, 250);
}, "Invalid incorporation date");


export const taxIdString = Z.string().length(9, "Tax ID must be 9 characters long");

export const INDUSTRY = 'industry';
export const BUSINESS_DETAILS = 'business_details';
export const BUSINESS_CONTACT_INFO = 'business_contact_info';
export const OWNER_DETAILS = 'owner_details';
export const PROCESSING_DETAILS = 'processing_details';
export const BANKING_DETAILS = 'banking_details';
export const SUMMARY = 'summary';

export const onboardingSectionArray = [INDUSTRY, BUSINESS_DETAILS, BUSINESS_CONTACT_INFO, OWNER_DETAILS, PROCESSING_DETAILS, BANKING_DETAILS, SUMMARY];

export type OnboardingSection = typeof onboardingSectionArray[number];

export const findCurrentAndNextHash = (section: string): { current: OnboardingSection, next: OnboardingSection | null } => {
    const itemKeys = progressItems.map(item => item.key);
    if (itemKeys.includes(section)) {
        window.location.hash = section
        const index = itemKeys.indexOf(section);
        if (index === itemKeys.length - 1) {
            return {current: section, next: null};
        } else {
            return {current: section, next: itemKeys[index + 1]};
        }
    } else {
        window.location.hash = INDUSTRY;
        return {current: INDUSTRY, next: progressItems[1].key};
    }
}

export type progressItem = {
    key: OnboardingSection,
    title: {
        main: string,
        school: string,
    }
}

export const progressItems: progressItem[] = [
    {
        key: INDUSTRY,
        title: {
            main: "Industry",
            school: "Industry",
        },
    },
    {
        key: BUSINESS_DETAILS,
        title: {
            main: "Business Details",
            school: "School Details",
        },
    },
    {
        key: BUSINESS_CONTACT_INFO,
        title: {
            main: "Business Contact Information",
            school: "School Contact Information",
        }
    },
    {
        key: OWNER_DETAILS,
        title: {
            main: "Owner Details",
            school: "School Representatives",
        }
    },
    {
        key: PROCESSING_DETAILS,
        title: {
            main: "Processing Details",
            school: "Processing Details",
        }
    },
    {
        key: BANKING_DETAILS,
        title: {
            main: "Banking Details",
            school: "Banking Details",
        }
    },
    {
        key: SUMMARY,
        title: {
            main: "Summary",
            school: "Summary",
        }
    }];

type NestedKeyOf<ObjectType extends object> =
    {
        [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
        ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
        : `${Key}`
    }[keyof ObjectType & (string | number)];

export type OnboardingStateKeys = NestedKeyOf<typeof defaultOnboardingState>

export const defaultOnboardingState = {
    merchant_uid: "",
    is_locked: false,
    ticket_id: undefined,
    needs_docs: false,
    user_email: undefined,
    user_full_name: "",
    business: {
        additional_underwriting_data: {
            annual_ach_volume: undefined,
            average_ach_transfer_amount: undefined,
            average_card_transfer_amount: undefined,
            business_description: "",
            card_volume_distribution: {
                card_present_percentage: undefined,
                ecommerce_percentage: undefined,
            },
            credit_check_allowed: false,
            credit_check_ip_address: undefined,
            credit_check_timestamp: undefined,
            credit_check_user_agent: "",
            merchant_agreement_accepted: false,
            merchant_agreement_ip_address: undefined,
            merchant_agreement_timestamp: undefined,
            merchant_agreement_user_agent: "",
            statement_descriptor: "",
            refund_policy: undefined, // Check these
            volume_distribution_by_business_type: {
                other_volume_percentage: undefined,
                business_to_consumer_volume_percentage: undefined,
                business_to_business_volume_percentage: undefined,
            },
        },
        underwriting_data: {
            annual_card_volume: undefined,
            business_address: {
                city: "",
                country: "",
                region: "",
                line2: "",
                line1: "",
                postal_code: "",
            },
            has_accepted_credit_cards_previously: true,
            business_name: "",
            business_type: undefined,
            doing_business_as: "",
            incorporation_date: undefined,
            max_transaction_amount: undefined,
            mcc: "",
            phone: undefined,
            ownership_type: undefined,
            tax_id: "",
            url: undefined,
        }
    },
    business_owners: [
        {
            uid: "",
            first_name: "",
            middle_initial: "",
            last_name: "",
            title: "",
            dob: undefined,
            tax_id: "",
            email: undefined,
            phone: undefined,
            address: {
                line1: "",
                line2: "",
                city: "",
                postal_code: "",
                region: "",
                country: "",
            },
            primary_owner: true,
            percentage_ownership: undefined
        }
    ],
    bank: {
        account_number: "",
        confirm_account_number: "",
        account_type: "",
        bank_code: "",
        confirm_bank_code: "",
        name: "",
    }
}

export const convertNullToEmptyStrings = (inputObject: object): object => {
    const copy = JSON.parse(JSON.stringify(inputObject))
    Object.keys(copy).forEach((key) => {
        if (copy[key] === null) {
            copy[key] = "";
        } else if (copy[key] instanceof Object) {
            copy[key] = convertNullToEmptyStrings(copy[key]);
        }
    })
    return copy;
}

const convertNumbersToStrings = (inputObject: object): object => {
    const copy = JSON.parse(JSON.stringify(inputObject))
    Object.keys(copy).forEach((key) => {
        if (typeof copy[key] === "number") {
            copy[key] = copy[key].toString();
        } else if (copy[key] instanceof Object) {
            copy[key] = convertNumbersToStrings(copy[key]);
        }
    })
    return copy;
}

const formatDollarsToCents = (value: string): number | null => {
    let parsed = safeParseInt(String(value).replace(/[^0-9]/g, ''));
    if (parsed === null) return parsed;
    return parsed * 100;
}

export const prepFormDataForServer = (inputObject: typeof defaultOnboardingState): MerchantOnboarding => {
    const copy = JSON.parse(JSON.stringify(inputObject));
    // Convert all number values from strings back to numbers
    copy.ticket_id = safeParseInt(copy.ticket_id);
    copy.business.underwriting_data.annual_card_volume = formatDollarsToCents(copy.business.underwriting_data.annual_card_volume)
    copy.business.underwriting_data.max_transaction_amount = formatDollarsToCents(copy.business.underwriting_data.max_transaction_amount)
    copy.business.additional_underwriting_data.annual_ach_volume = formatDollarsToCents(copy.business.additional_underwriting_data.annual_ach_volume)
    copy.business.additional_underwriting_data.average_ach_transfer_amount = formatDollarsToCents(copy.business.additional_underwriting_data.average_ach_transfer_amount)
    copy.business.additional_underwriting_data.average_card_transfer_amount = formatDollarsToCents(copy.business.additional_underwriting_data.average_card_transfer_amount)

    // Convert percentages to integers and make sure they add up to 100. If they do set null to 0.
    const volumeDistribution = copy.business.additional_underwriting_data.card_volume_distribution;
    volumeDistribution.card_present_percentage = safeParseInt(String(volumeDistribution.card_present_percentage).replace(/[^0-9]/g, ''));
    volumeDistribution.ecommerce_percentage = safeParseInt(String(volumeDistribution.ecommerce_percentage).replace(/[^0-9]/g, ''));
    if((volumeDistribution.card_present_percentage ?? 0 + volumeDistribution.ecommerce_percentage ?? 0) === 100) {
        volumeDistribution.card_present_percentage = volumeDistribution.card_present_percentage ?? 0;
        volumeDistribution.ecommerce_percentage = volumeDistribution.ecommerce_percentage ?? 0;
    }

    const businessPercentage = copy.business.additional_underwriting_data.volume_distribution_by_business_type;
    businessPercentage.business_to_business_volume_percentage = safeParseInt(String(businessPercentage.business_to_business_volume_percentage).replace(/[^0-9]/g, ''));
    businessPercentage.business_to_consumer_volume_percentage = safeParseInt(String(businessPercentage.business_to_consumer_volume_percentage).replace(/[^0-9]/g, ''));
    businessPercentage.other_volume_percentage = safeParseInt(String(businessPercentage.other_volume_percentage).replace(/[^0-9]/g, ''));
    if((businessPercentage.business_to_business_volume_percentage ?? 0 + businessPercentage.business_to_consumer_volume_percentage ?? 0 + businessPercentage.other_volume_percentage ?? 0) === 100) {
        businessPercentage.business_to_business_volume_percentage = businessPercentage.business_to_business_volume_percentage ?? 0;
        businessPercentage.business_to_consumer_volume_percentage = businessPercentage.business_to_consumer_volume_percentage ?? 0;
        businessPercentage.other_volume_percentage = businessPercentage.other_volume_percentage ?? 0;
    }

    // Convert null percentages to 0 if

    copy.business_owners = copy.business_owners.map((owner: any) => {
        owner = convertEmptyStringsToNull(owner);
        owner.percentage_ownership = safeParseInt(String(owner.percentage_ownership).replace(/[^0-9]/g, ''));
        if (typeof owner.dob === "object" && owner.dob) {
            owner.dob = convertDateToString(owner.dob)
        }
        if (!owner.uid) owner.uid = uuidv4()
        if (owner.phone) owner.phone = preparePhoneForAWS(owner.phone)
        if (owner.primary_owner === null) owner.primary_owner = false
        return owner
    })

    // Convert all boolean values from strings back to booleans
    if (typeof copy.business.underwriting_data.has_accepted_credit_cards_previously === "string") {
        copy.business.underwriting_data.has_accepted_credit_cards_previously = copy.business.underwriting_data.has_accepted_credit_cards_previously === "true";
    }

    // Convert all date values from a DateValue object to a string
    if (typeof copy.business.underwriting_data.incorporation_date === "object" && copy.business.underwriting_data.incorporation_date) {
        copy.business.underwriting_data.incorporation_date = convertDateToString(copy.business.underwriting_data.incorporation_date)
    }
    if (copy.business.underwriting_data.phone) copy.business.underwriting_data.phone = preparePhoneForAWS(copy.business.underwriting_data.phone)

    // Remove the confirmation bank fields
    delete copy.bank.confirm_bank_code;
    delete copy.bank.confirm_account_number;

    return convertEmptyStringsToNull(copy) as MerchantOnboarding;
}

// Formats the amounts and makes sure they are in whole dollars and not cents
const initialDollarFormat = (value: string) => {
    if (value === "") return "";
    const number = Number(value);
    if (isNaN(number)) return "";
    const numberString = (number / 100).toString();
    return formatDollarInputValue(numberString, false);
}

export const prepOnboardingDataForForm = (inputObject: typeof defaultOnboardingState): typeof defaultOnboardingState => {
    let copy = JSON.parse(JSON.stringify(inputObject));
    copy = convertNumbersToStrings(copy);
    copy = convertNullToEmptyStrings(copy);

    copy.business_owners = copy.business_owners.map((owner: any) => {
        owner = convertNumbersToStrings(owner);
        owner = convertNullToEmptyStrings(owner);
        return owner;
    })

    //Format dollar values
    copy.business.underwriting_data.annual_card_volume = initialDollarFormat(copy.business.underwriting_data.annual_card_volume || "")
    copy.business.additional_underwriting_data.average_card_transfer_amount = initialDollarFormat(copy.business.additional_underwriting_data.average_card_transfer_amount || "")
    copy.business.underwriting_data.max_transaction_amount = initialDollarFormat(copy.business.underwriting_data.max_transaction_amount || "")
    copy.business.additional_underwriting_data.annual_ach_volume = initialDollarFormat(copy.business.additional_underwriting_data.annual_ach_volume || "")
    copy.business.additional_underwriting_data.average_ach_transfer_amount = initialDollarFormat(copy.business.additional_underwriting_data.average_ach_transfer_amount || "")

    // Create a confirm account and routing number value on the bank object
    copy.bank.confirm_account_number = copy.bank.account_number;
    copy.bank.confirm_bank_code = copy.bank.bank_code;

    return copy as typeof defaultOnboardingState;
}

export const onboardingStateChanged = (lastFetched: MerchantOnboarding, current: typeof defaultOnboardingState): boolean => {
    // Check for changes for the confirm bank fields
    if (current.bank.confirm_account_number !== current.bank.account_number) return true;
    if (current.bank.confirm_bank_code !== current.bank.bank_code) return true;
    const currentNormalized = prepFormDataForServer(current);
    return JSON.stringify(lastFetched) !== JSON.stringify(currentNormalized);
}

export const createHelperText = <T extends { [key: string]: string[] }>(helpText: T) => <K extends keyof T>(key: K): React.ReactElement => {
    return (
        <div>
            {helpText[key].map((text, index) => {
                if (text === "spacer") {
                    return (
                        <br key={index}/>
                    )
                }
                return (
                    <p style={{
                        fontStyle: text === "Example:" ? "italic" : "normal",
                    }} className={"grey"} key={index}>{text}</p>
                )
            })}
            <br/>
            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "125px"
            }}>
                <Button text
                        leadingIcon={{name: "question-circle"}}
                        onPress={() => {
                            // @ts-ignore
                            FreshworksWidget('open');
                        }}
                        excludeFromTabOrder
                >
                    Need Help?
                </Button>
            </div>
        </div>
    )
}

// All simple ISO 3166 country data
// including: 2-character, 3-character, full country name and numeric code.
// Sorted alphabetical by country name (special characters on bottom)
export const countryListAllIsoData: { code: string, code3: string, name: string, number: string }[] = [
    {"code": "AF", "code3": "AFG", "name": "Afghanistan", "number": "004"},
    {"code": "AL", "code3": "ALB", "name": "Albania", "number": "008"},
    {"code": "DZ", "code3": "DZA", "name": "Algeria", "number": "012"},
    {"code": "AS", "code3": "ASM", "name": "American Samoa", "number": "016"},
    {"code": "AD", "code3": "AND", "name": "Andorra", "number": "020"},
    {"code": "AO", "code3": "AGO", "name": "Angola", "number": "024"},
    {"code": "AI", "code3": "AIA", "name": "Anguilla", "number": "660"},
    {"code": "AQ", "code3": "ATA", "name": "Antarctica", "number": "010"},
    {"code": "AG", "code3": "ATG", "name": "Antigua and Barbuda", "number": "028"},
    {"code": "AR", "code3": "ARG", "name": "Argentina", "number": "032"},
    {"code": "AM", "code3": "ARM", "name": "Armenia", "number": "051"},
    {"code": "AW", "code3": "ABW", "name": "Aruba", "number": "533"},
    {"code": "AU", "code3": "AUS", "name": "Australia", "number": "036"},
    {"code": "AT", "code3": "AUT", "name": "Austria", "number": "040"},
    {"code": "AZ", "code3": "AZE", "name": "Azerbaijan", "number": "031"},
    {"code": "BS", "code3": "BHS", "name": "Bahamas", "number": "044"},
    {"code": "BH", "code3": "BHR", "name": "Bahrain", "number": "048"},
    {"code": "BD", "code3": "BGD", "name": "Bangladesh", "number": "050"},
    {"code": "BB", "code3": "BRB", "name": "Barbados", "number": "052"},
    {"code": "BY", "code3": "BLR", "name": "Belarus", "number": "112"},
    {"code": "BE", "code3": "BEL", "name": "Belgium", "number": "056"},
    {"code": "BZ", "code3": "BLZ", "name": "Belize", "number": "084"},
    {"code": "BJ", "code3": "BEN", "name": "Benin", "number": "204"},
    {"code": "BM", "code3": "BMU", "name": "Bermuda", "number": "060"},
    {"code": "BT", "code3": "BTN", "name": "Bhutan", "number": "064"},
    {"code": "BO", "code3": "BOL", "name": "Bolivia", "number": "068"},
    {"code": "BQ", "code3": "BES", "name": "Bonaire, Sint Eustatius and Saba", "number": "535"},
    {"code": "BA", "code3": "BIH", "name": "Bosnia and Herzegovina", "number": "070"},
    {"code": "BW", "code3": "BWA", "name": "Botswana", "number": "072"},
    {"code": "BV", "code3": "BVT", "name": "Bouvet Island", "number": "074"},
    {"code": "BR", "code3": "BRA", "name": "Brazil", "number": "076"},
    {"code": "IO", "code3": "IOT", "name": "British Indian Ocean Territory", "number": "086"},
    {"code": "BN", "code3": "BRN", "name": "Brunei Darussalam", "number": "096"},
    {"code": "BG", "code3": "BGR", "name": "Bulgaria", "number": "100"},
    {"code": "BF", "code3": "BFA", "name": "Burkina Faso", "number": "854"},
    {"code": "BI", "code3": "BDI", "name": "Burundi", "number": "108"},
    {"code": "CV", "code3": "CPV", "name": "Cabo Verde", "number": "132"},
    {"code": "KH", "code3": "KHM", "name": "Cambodia", "number": "116"},
    {"code": "CM", "code3": "CMR", "name": "Cameroon", "number": "120"},
    {"code": "CA", "code3": "CAN", "name": "Canada", "number": "124"},
    {"code": "KY", "code3": "CYM", "name": "Cayman Islands", "number": "136"},
    {"code": "CF", "code3": "CAF", "name": "Central African Republic", "number": "140"},
    {"code": "TD", "code3": "TCD", "name": "Chad", "number": "148"},
    {"code": "CL", "code3": "CHL", "name": "Chile", "number": "152"},
    {"code": "CN", "code3": "CHN", "name": "China", "number": "156"},
    {"code": "CX", "code3": "CXR", "name": "Christmas Island", "number": "162"},
    {"code": "CC", "code3": "CCK", "name": "Cocos (Keeling) Islands", "number": "166"},
    {"code": "CO", "code3": "COL", "name": "Colombia", "number": "170"},
    {"code": "KM", "code3": "COM", "name": "Comoros", "number": "174"},
    {"code": "CD", "code3": "COD", "name": "Congo (the Democratic Republic of the)", "number": "180"},
    {"code": "CG", "code3": "COG", "name": "Congo", "number": "178"},
    {"code": "CK", "code3": "COK", "name": "Cook Islands", "number": "184"},
    {"code": "CR", "code3": "CRI", "name": "Costa Rica", "number": "188"},
    {"code": "HR", "code3": "HRV", "name": "Croatia", "number": "191"},
    {"code": "CU", "code3": "CUB", "name": "Cuba", "number": "192"},
    {"code": "CW", "code3": "CUW", "name": "Curaçao", "number": "531"},
    {"code": "CY", "code3": "CYP", "name": "Cyprus", "number": "196"},
    {"code": "CZ", "code3": "CZE", "name": "Czechia", "number": "203"},
    {"code": "CI", "code3": "CIV", "name": "Côte d'Ivoire", "number": "384"},
    {"code": "DK", "code3": "DNK", "name": "Denmark", "number": "208"},
    {"code": "DJ", "code3": "DJI", "name": "Djibouti", "number": "262"},
    {"code": "DM", "code3": "DMA", "name": "Dominica", "number": "212"},
    {"code": "DO", "code3": "DOM", "name": "Dominican Republic", "number": "214"},
    {"code": "EC", "code3": "ECU", "name": "Ecuador", "number": "218"},
    {"code": "EG", "code3": "EGY", "name": "Egypt", "number": "818"},
    {"code": "SV", "code3": "SLV", "name": "El Salvador", "number": "222"},
    {"code": "GQ", "code3": "GNQ", "name": "Equatorial Guinea", "number": "226"},
    {"code": "ER", "code3": "ERI", "name": "Eritrea", "number": "232"},
    {"code": "EE", "code3": "EST", "name": "Estonia", "number": "233"},
    {"code": "SZ", "code3": "SWZ", "name": "Eswatini", "number": "748"},
    {"code": "ET", "code3": "ETH", "name": "Ethiopia", "number": "231"},
    {"code": "FK", "code3": "FLK", "name": "Falkland Islands [Malvinas]", "number": "238"},
    {"code": "FO", "code3": "FRO", "name": "Faroe Islands", "number": "234"},
    {"code": "FJ", "code3": "FJI", "name": "Fiji", "number": "242"},
    {"code": "FI", "code3": "FIN", "name": "Finland", "number": "246"},
    {"code": "FR", "code3": "FRA", "name": "France", "number": "250"},
    {"code": "GF", "code3": "GUF", "name": "French Guiana", "number": "254"},
    {"code": "PF", "code3": "PYF", "name": "French Polynesia", "number": "258"},
    {"code": "TF", "code3": "ATF", "name": "French Southern Territories", "number": "260"},
    {"code": "GA", "code3": "GAB", "name": "Gabon", "number": "266"},
    {"code": "GM", "code3": "GMB", "name": "Gambia", "number": "270"},
    {"code": "GE", "code3": "GEO", "name": "Georgia", "number": "268"},
    {"code": "DE", "code3": "DEU", "name": "Germany", "number": "276"},
    {"code": "GH", "code3": "GHA", "name": "Ghana", "number": "288"},
    {"code": "GI", "code3": "GIB", "name": "Gibraltar", "number": "292"},
    {"code": "GR", "code3": "GRC", "name": "Greece", "number": "300"},
    {"code": "GL", "code3": "GRL", "name": "Greenland", "number": "304"},
    {"code": "GD", "code3": "GRD", "name": "Grenada", "number": "308"},
    {"code": "GP", "code3": "GLP", "name": "Guadeloupe", "number": "312"},
    {"code": "GU", "code3": "GUM", "name": "Guam", "number": "316"},
    {"code": "GT", "code3": "GTM", "name": "Guatemala", "number": "320"},
    {"code": "GG", "code3": "GGY", "name": "Guernsey", "number": "831"},
    {"code": "GN", "code3": "GIN", "name": "Guinea", "number": "324"},
    {"code": "GW", "code3": "GNB", "name": "Guinea-Bissau", "number": "624"},
    {"code": "GY", "code3": "GUY", "name": "Guyana", "number": "328"},
    {"code": "HT", "code3": "HTI", "name": "Haiti", "number": "332"},
    {"code": "HM", "code3": "HMD", "name": "Heard Island and McDonald Islands", "number": "334"},
    {"code": "VA", "code3": "VAT", "name": "Holy See", "number": "336"},
    {"code": "HN", "code3": "HND", "name": "Honduras", "number": "340"},
    {"code": "HK", "code3": "HKG", "name": "Hong Kong", "number": "344"},
    {"code": "HU", "code3": "HUN", "name": "Hungary", "number": "348"},
    {"code": "IS", "code3": "ISL", "name": "Iceland", "number": "352"},
    {"code": "IN", "code3": "IND", "name": "India", "number": "356"},
    {"code": "ID", "code3": "IDN", "name": "Indonesia", "number": "360"},
    {"code": "IR", "code3": "IRN", "name": "Iran", "number": "364"},
    {"code": "IQ", "code3": "IRQ", "name": "Iraq", "number": "368"},
    {"code": "IE", "code3": "IRL", "name": "Ireland", "number": "372"},
    {"code": "IM", "code3": "IMN", "name": "Isle of Man", "number": "833"},
    {"code": "IL", "code3": "ISR", "name": "Israel", "number": "376"},
    {"code": "IT", "code3": "ITA", "name": "Italy", "number": "380"},
    {"code": "JM", "code3": "JAM", "name": "Jamaica", "number": "388"},
    {"code": "JP", "code3": "JPN", "name": "Japan", "number": "392"},
    {"code": "JE", "code3": "JEY", "name": "Jersey", "number": "832"},
    {"code": "JO", "code3": "JOR", "name": "Jordan", "number": "400"},
    {"code": "KZ", "code3": "KAZ", "name": "Kazakhstan", "number": "398"},
    {"code": "KE", "code3": "KEN", "name": "Kenya", "number": "404"},
    {"code": "KI", "code3": "KIR", "name": "Kiribati", "number": "296"},
    {"code": "KP", "code3": "PRK", "name": "Korea (the Democratic People's Republic of)", "number": "408"},
    {"code": "KR", "code3": "KOR", "name": "Korea (the Republic of)", "number": "410"},
    {"code": "KW", "code3": "KWT", "name": "Kuwait", "number": "414"},
    {"code": "KG", "code3": "KGZ", "name": "Kyrgyzstan", "number": "417"},
    {"code": "LA", "code3": "LAO", "name": "Lao People's Democratic Republic", "number": "418"},
    {"code": "LV", "code3": "LVA", "name": "Latvia", "number": "428"},
    {"code": "LB", "code3": "LBN", "name": "Lebanon", "number": "422"},
    {"code": "LS", "code3": "LSO", "name": "Lesotho", "number": "426"},
    {"code": "LR", "code3": "LBR", "name": "Liberia", "number": "430"},
    {"code": "LY", "code3": "LBY", "name": "Libya", "number": "434"},
    {"code": "LI", "code3": "LIE", "name": "Liechtenstein", "number": "438"},
    {"code": "LT", "code3": "LTU", "name": "Lithuania", "number": "440"},
    {"code": "LU", "code3": "LUX", "name": "Luxembourg", "number": "442"},
    {"code": "MO", "code3": "MAC", "name": "Macao", "number": "446"},
    {"code": "MG", "code3": "MDG", "name": "Madagascar", "number": "450"},
    {"code": "MW", "code3": "MWI", "name": "Malawi", "number": "454"},
    {"code": "MY", "code3": "MYS", "name": "Malaysia", "number": "458"},
    {"code": "MV", "code3": "MDV", "name": "Maldives", "number": "462"},
    {"code": "ML", "code3": "MLI", "name": "Mali", "number": "466"},
    {"code": "MT", "code3": "MLT", "name": "Malta", "number": "470"},
    {"code": "MH", "code3": "MHL", "name": "Marshall Islands", "number": "584"},
    {"code": "MQ", "code3": "MTQ", "name": "Martinique", "number": "474"},
    {"code": "MR", "code3": "MRT", "name": "Mauritania", "number": "478"},
    {"code": "MU", "code3": "MUS", "name": "Mauritius", "number": "480"},
    {"code": "YT", "code3": "MYT", "name": "Mayotte", "number": "175"},
    {"code": "MX", "code3": "MEX", "name": "Mexico", "number": "484"},
    {"code": "FM", "code3": "FSM", "name": "Micronesia (Federated States of)", "number": "583"},
    {"code": "MD", "code3": "MDA", "name": "Moldova", "number": "498"},
    {"code": "MC", "code3": "MCO", "name": "Monaco", "number": "492"},
    {"code": "MN", "code3": "MNG", "name": "Mongolia", "number": "496"},
    {"code": "ME", "code3": "MNE", "name": "Montenegro", "number": "499"},
    {"code": "MS", "code3": "MSR", "name": "Montserrat", "number": "500"},
    {"code": "MA", "code3": "MAR", "name": "Morocco", "number": "504"},
    {"code": "MZ", "code3": "MOZ", "name": "Mozambique", "number": "508"},
    {"code": "MM", "code3": "MMR", "name": "Myanmar", "number": "104"},
    {"code": "NA", "code3": "NAM", "name": "Namibia", "number": "516"},
    {"code": "NR", "code3": "NRU", "name": "Nauru", "number": "520"},
    {"code": "NP", "code3": "NPL", "name": "Nepal", "number": "524"},
    {"code": "NL", "code3": "NLD", "name": "Netherlands", "number": "528"},
    {"code": "NC", "code3": "NCL", "name": "New Caledonia", "number": "540"},
    {"code": "NZ", "code3": "NZL", "name": "New Zealand", "number": "554"},
    {"code": "NI", "code3": "NIC", "name": "Nicaragua", "number": "558"},
    {"code": "NE", "code3": "NER", "name": "Niger", "number": "562"},
    {"code": "NG", "code3": "NGA", "name": "Nigeria", "number": "566"},
    {"code": "NU", "code3": "NIU", "name": "Niue", "number": "570"},
    {"code": "NF", "code3": "NFK", "name": "Norfolk Island", "number": "574"},
    {"code": "MP", "code3": "MNP", "name": "Northern Mariana Islands", "number": "580"},
    {"code": "NO", "code3": "NOR", "name": "Norway", "number": "578"},
    {"code": "OM", "code3": "OMN", "name": "Oman", "number": "512"},
    {"code": "PK", "code3": "PAK", "name": "Pakistan", "number": "586"},
    {"code": "PW", "code3": "PLW", "name": "Palau", "number": "585"},
    {"code": "PS", "code3": "PSE", "name": "Palestine", "number": "275"},
    {"code": "PA", "code3": "PAN", "name": "Panama", "number": "591"},
    {"code": "PG", "code3": "PNG", "name": "Papua New Guinea", "number": "598"},
    {"code": "PY", "code3": "PRY", "name": "Paraguay", "number": "600"},
    {"code": "PE", "code3": "PER", "name": "Peru", "number": "604"},
    {"code": "PH", "code3": "PHL", "name": "Philippines", "number": "608"},
    {"code": "PN", "code3": "PCN", "name": "Pitcairn", "number": "612"},
    {"code": "PL", "code3": "POL", "name": "Poland", "number": "616"},
    {"code": "PT", "code3": "PRT", "name": "Portugal", "number": "620"},
    {"code": "PR", "code3": "PRI", "name": "Puerto Rico", "number": "630"},
    {"code": "QA", "code3": "QAT", "name": "Qatar", "number": "634"},
    {"code": "MK", "code3": "MKD", "name": "Republic of North Macedonia", "number": "807"},
    {"code": "RO", "code3": "ROU", "name": "Romania", "number": "642"},
    {"code": "RU", "code3": "RUS", "name": "Russia", "number": "643"},
    {"code": "RW", "code3": "RWA", "name": "Rwanda", "number": "646"},
    {"code": "RE", "code3": "REU", "name": "Réunion", "number": "638"},
    {"code": "BL", "code3": "BLM", "name": "Saint Barthélemy", "number": "652"},
    {"code": "SH", "code3": "SHN", "name": "Saint Helena, Ascension and Tristan da Cunha", "number": "654"},
    {"code": "KN", "code3": "KNA", "name": "Saint Kitts and Nevis", "number": "659"},
    {"code": "LC", "code3": "LCA", "name": "Saint Lucia", "number": "662"},
    {"code": "MF", "code3": "MAF", "name": "Saint Martin (French part)", "number": "663"},
    {"code": "PM", "code3": "SPM", "name": "Saint Pierre and Miquelon", "number": "666"},
    {"code": "VC", "code3": "VCT", "name": "Saint Vincent and the Grenadines", "number": "670"},
    {"code": "WS", "code3": "WSM", "name": "Samoa", "number": "882"},
    {"code": "SM", "code3": "SMR", "name": "San Marino", "number": "674"},
    {"code": "ST", "code3": "STP", "name": "Sao Tome and Principe", "number": "678"},
    {"code": "SA", "code3": "SAU", "name": "Saudi Arabia", "number": "682"},
    {"code": "SN", "code3": "SEN", "name": "Senegal", "number": "686"},
    {"code": "RS", "code3": "SRB", "name": "Serbia", "number": "688"},
    {"code": "SC", "code3": "SYC", "name": "Seychelles", "number": "690"},
    {"code": "SL", "code3": "SLE", "name": "Sierra Leone", "number": "694"},
    {"code": "SG", "code3": "SGP", "name": "Singapore", "number": "702"},
    {"code": "SX", "code3": "SXM", "name": "Sint Maarten", "number": "534"},
    {"code": "SK", "code3": "SVK", "name": "Slovakia", "number": "703"},
    {"code": "SI", "code3": "SVN", "name": "Slovenia", "number": "705"},
    {"code": "SB", "code3": "SLB", "name": "Solomon Islands", "number": "090"},
    {"code": "SO", "code3": "SOM", "name": "Somalia", "number": "706"},
    {"code": "ZA", "code3": "ZAF", "name": "South Africa", "number": "710"},
    {"code": "GS", "code3": "SGS", "name": "South Georgia and the South Sandwich Islands", "number": "239"},
    {"code": "SS", "code3": "SSD", "name": "South Sudan", "number": "728"},
    {"code": "ES", "code3": "ESP", "name": "Spain", "number": "724"},
    {"code": "LK", "code3": "LKA", "name": "Sri Lanka", "number": "144"},
    {"code": "SD", "code3": "SDN", "name": "Sudan", "number": "729"},
    {"code": "SR", "code3": "SUR", "name": "Suriname", "number": "740"},
    {"code": "SJ", "code3": "SJM", "name": "Svalbard and Jan Mayen", "number": "744"},
    {"code": "SE", "code3": "SWE", "name": "Sweden", "number": "752"},
    {"code": "CH", "code3": "CHE", "name": "Switzerland", "number": "756"},
    {"code": "SY", "code3": "SYR", "name": "Syrian Arab Republic", "number": "760"},
    {"code": "TW", "code3": "TWN", "name": "Taiwan", "number": "158"},
    {"code": "TJ", "code3": "TJK", "name": "Tajikistan", "number": "762"},
    {"code": "TZ", "code3": "TZA", "name": "Tanzania, United Republic of", "number": "834"},
    {"code": "TH", "code3": "THA", "name": "Thailand", "number": "764"},
    {"code": "TL", "code3": "TLS", "name": "Timor-Leste", "number": "626"},
    {"code": "TG", "code3": "TGO", "name": "Togo", "number": "768"},
    {"code": "TK", "code3": "TKL", "name": "Tokelau", "number": "772"},
    {"code": "TO", "code3": "TON", "name": "Tonga", "number": "776"},
    {"code": "TT", "code3": "TTO", "name": "Trinidad and Tobago", "number": "780"},
    {"code": "TN", "code3": "TUN", "name": "Tunisia", "number": "788"},
    {"code": "TR", "code3": "TUR", "name": "Turkey", "number": "792"},
    {"code": "TM", "code3": "TKM", "name": "Turkmenistan", "number": "795"},
    {"code": "TC", "code3": "TCA", "name": "Turks and Caicos Islands", "number": "796"},
    {"code": "TV", "code3": "TUV", "name": "Tuvalu", "number": "798"},
    {"code": "UG", "code3": "UGA", "name": "Uganda", "number": "800"},
    {"code": "UA", "code3": "UKR", "name": "Ukraine", "number": "804"},
    {"code": "AE", "code3": "ARE", "name": "United Arab Emirates", "number": "784"},
    {"code": "GB", "code3": "GBR", "name": "United Kingdom of Great Britain and Northern Ireland", "number": "826"},
    {"code": "UM", "code3": "UMI", "name": "United States Minor Outlying Islands", "number": "581"},
    {"code": "US", "code3": "USA", "name": "United States of America", "number": "840"},
    {"code": "UY", "code3": "URY", "name": "Uruguay", "number": "858"},
    {"code": "UZ", "code3": "UZB", "name": "Uzbekistan", "number": "860"},
    {"code": "VU", "code3": "VUT", "name": "Vanuatu", "number": "548"},
    {"code": "VE", "code3": "VEN", "name": "Venezuela", "number": "862"},
    {"code": "VN", "code3": "VNM", "name": "Viet Nam", "number": "704"},
    {"code": "VG", "code3": "VGB", "name": "Virgin Islands (British)", "number": "092"},
    {"code": "VI", "code3": "VIR", "name": "Virgin Islands (U.S.)", "number": "850"},
    {"code": "WF", "code3": "WLF", "name": "Wallis and Futuna", "number": "876"},
    {"code": "EH", "code3": "ESH", "name": "Western Sahara", "number": "732"},
    {"code": "YE", "code3": "YEM", "name": "Yemen", "number": "887"},
    {"code": "ZM", "code3": "ZMB", "name": "Zambia", "number": "894"},
    {"code": "ZW", "code3": "ZWE", "name": "Zimbabwe", "number": "716"},
    {"code": "AX", "code3": "ALA", "name": "Åland Islands", "number": "248"}
];

export const callingCodes = {
    "US": "+1",
    "CA": "+1",
    "BS": "+1 242",
    "BB": "+1 246",
    "AI": "+1 264",
    "AG": "+1 268",
    "VG": "+1 284",
    "VI": "+1 340",
    "KY": "+1 345",
    "BM": "+1 441",
    "GD": "+1 473",
    "TC": "+1 649",
    "JM": "+1 876",
    "MS": "+1 664",
    "MP": "+1 670",
    "GU": "+1 671",
    "AS": "+1 684",
    "SX": "+1 721",
    "LC": "+1 758",
    "DM": "+1 767",
    "VC": "+1 784",
    "PR": "+1 939",
    "DO": "+1 849",
    "TT": "+1 868",
    "KN": "+1 869",
    "EG": "+20",
    "ZA": "+27",
    "SS": "+211",
    "MA": "+212",
    "EH": "+212",
    "DZ": "+213",
    "TN": "+216",
    "LY": "+218",
    "GM": "+220",
    "SN": "+221",
    "MR": "+222",
    "ML": "+223",
    "GN": "+224",
    "CI": "+225",
    "BF": "+226",
    "NE": "+227",
    "TG": "+228",
    "BJ": "+229",
    "MU": "+230",
    "LR": "+231",
    "SL": "+232",
    "GH": "+233",
    "NG": "+234",
    "TD": "+235",
    "CF": "+236",
    "CM": "+237",
    "CV": "+238",
    "ST": "+239",
    "GQ": "+240",
    "GA": "+241",
    "CG": "+242",
    "CD": "+243",
    "AO": "+244",
    "GW": "+245",
    "IO": "+246",
    "AC": "+247",
    "SC": "+248",
    "SD": "+249",
    "RW": "+250",
    "ET": "+251",
    "SO": "+252",
    "DJ": "+253",
    "KE": "+254",
    "TZ": "+255",
    "UG": "+256",
    "BI": "+257",
    "MZ": "+258",
    "ZM": "+260",
    "MG": "+261",
    "RE": "+262",
    "YT": "+262",
    "TF": "+262",
    "ZW": "+263",
    "NA": "+264",
    "MW": "+265",
    "LS": "+266",
    "BW": "+267",
    "SZ": "+268",
    "KM": "+269",
    "SH": "+290",
    "TA": "+290",
    "ER": "+291",
    "AW": "+297",
    "FO": "+298",
    "GL": "+299",
    "GR": "+30",
    "NL": "+31",
    "BE": "+32",
    "FR": "+33",
    "ES": "+34",
    "HU": "+36",
    "IT": "+39",
    "VA": "+379",
    "GI": "+350",
    "PT": "+351",
    "LU": "+352",
    "IE": "+353",
    "IS": "+354",
    "AL": "+355",
    "MT": "+356",
    "CY": "+357",
    "FI": "+358",
    "AX": "+358",
    "BG": "+359",
    "LT": "+370",
    "LV": "+371",
    "EE": "+372",
    "MD": "+373",
    "AM": "+374",
    "QN": "+374",
    "BY": "+375",
    "AD": "+376",
    "MC": "+377",
    "SM": "+378",
    "UA": "+380",
    "RS": "+381",
    "ME": "+382",
    "XK": "+383",
    "HR": "+385",
    "SI": "+386",
    "BA": "+387",
    "MK": "+389",
    "RO": "+40",
    "CH": "+41",
    "AT": "+43",
    "GB": "+44",
    "GG": "+44",
    "IM": "+44",
    "JE": "+44",
    "DK": "+45",
    "SE": "+46",
    "NO": "+47",
    "SJ": "+47",
    "BV": "+47",
    "PL": "+48",
    "DE": "+49",
    "CZ": "+420",
    "SK": "+421",
    "LI": "+423",
    "PE": "+51",
    "MX": "+52",
    "CU": "+53",
    "AR": "+54",
    "BR": "+55",
    "CL": "+56",
    "CO": "+57",
    "VE": "+58",
    "FK": "+500",
    "GS": "+500",
    "BZ": "+501",
    "GT": "+502",
    "SV": "+503",
    "HN": "+504",
    "NI": "+505",
    "CR": "+506",
    "PA": "+507",
    "PM": "+508",
    "HT": "+509",
    "GP": "+590",
    "BL": "+590",
    "MF": "+590",
    "BO": "+591",
    "GY": "+592",
    "EC": "+593",
    "GF": "+594",
    "PY": "+595",
    "MQ": "+596",
    "SR": "+597",
    "UY": "+598",
    "BQ": "+599",
    "CW": "+599",
    "MY": "+60",
    "AU": "+61",
    "CX": "+61",
    "CC": "+61",
    "ID": "+62",
    "PH": "+63",
    "NZ": "+64",
    "PN": "+64",
    "SG": "+65",
    "TH": "+66",
    "TL": "+670",
    "NF": "+672",
    "AQ": "+672",
    "HM": "+672",
    "BN": "+673",
    "NR": "+674",
    "PG": "+675",
    "TO": "+676",
    "SB": "+677",
    "VU": "+678",
    "FJ": "+679",
    "PW": "+680",
    "WF": "+681",
    "CK": "+682",
    "NU": "+683",
    "WS": "+685",
    "KI": "+686",
    "NC": "+687",
    "TV": "+688",
    "PF": "+689",
    "TK": "+690",
    "FM": "+691",
    "MH": "+692",
    "RU": "+7",
    "KZ": "+997",
    "JP": "+81",
    "KR": "+82",
    "VN": "+84",
    "CN": "+86",
    "KP": "+850",
    "HK": "+852",
    "MO": "+853",
    "KH": "+855",
    "LA": "+856",
    "BD": "+880",
    "TW": "+886",
    "UN": "+888",
    "TR": "+90",
    "CT": "+90",
    "IN": "+91",
    "PK": "+92",
    "AF": "+93",
    "LK": "+94",
    "MM": "+95",
    "IR": "+98",
    "MV": "+960",
    "LB": "+961",
    "JO": "+962",
    "SY": "+963",
    "IQ": "+964",
    "KW": "+965",
    "SA": "+966",
    "YE": "+967",
    "OM": "+968",
    "PS": "+970",
    "AE": "+971",
    "IL": "+972",
    "BH": "+973",
    "QA": "+974",
    "BT": "+975",
    "MN": "+976",
    "NP": "+977",
    "TJ": "+992",
    "TM": "+993",
    "AZ": "+994",
    "GE": "+995",
    "KG": "+996",
    "UZ": "+998"
}