export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
};

export type AcceptedPaymentMethods = {
  __typename?: 'AcceptedPaymentMethods';
  ach?: Maybe<Scalars['Boolean']>;
  card?: Maybe<Scalars['Boolean']>;
  cash?: Maybe<Scalars['Boolean']>;
};

export enum AcceptedPaymentMethodsEnum {
  All = 'ALL',
  NotAch = 'NOT_ACH',
  NotCard = 'NOT_CARD',
  NotCash = 'NOT_CASH',
  OnlyAch = 'ONLY_ACH',
  OnlyCard = 'ONLY_CARD',
  OnlyCash = 'ONLY_CASH'
}

export type AdditionalUnderwritingData = {
  __typename?: 'AdditionalUnderwritingData';
  annual_ach_volume?: Maybe<Scalars['Int']>;
  average_ach_transfer_amount?: Maybe<Scalars['Int']>;
  average_card_transfer_amount?: Maybe<Scalars['Int']>;
  business_description?: Maybe<Scalars['String']>;
  card_volume_distribution?: Maybe<CardVolumeDistribution>;
  credit_check_allowed?: Maybe<Scalars['Boolean']>;
  credit_check_ip_address?: Maybe<Scalars['AWSIPAddress']>;
  credit_check_timestamp?: Maybe<Scalars['AWSDateTime']>;
  credit_check_user_agent?: Maybe<Scalars['String']>;
  merchant_agreement_accepted?: Maybe<Scalars['Boolean']>;
  merchant_agreement_ip_address?: Maybe<Scalars['AWSIPAddress']>;
  merchant_agreement_timestamp?: Maybe<Scalars['AWSDateTime']>;
  merchant_agreement_user_agent?: Maybe<Scalars['String']>;
  refund_policy?: Maybe<Refund_Policy>;
  statement_descriptor?: Maybe<Scalars['String']>;
  volume_distribution_by_business_type?: Maybe<VolumeDistributionByBusinessType>;
};

export type AdditionalUnderwritingDataInput = {
  annual_ach_volume?: InputMaybe<Scalars['Int']>;
  average_ach_transfer_amount?: InputMaybe<Scalars['Int']>;
  average_card_transfer_amount?: InputMaybe<Scalars['Int']>;
  business_description?: InputMaybe<Scalars['String']>;
  card_volume_distribution?: InputMaybe<CardVolumeDistributionInput>;
  credit_check_allowed?: InputMaybe<Scalars['Boolean']>;
  credit_check_ip_address?: InputMaybe<Scalars['AWSIPAddress']>;
  credit_check_timestamp?: InputMaybe<Scalars['AWSDateTime']>;
  credit_check_user_agent?: InputMaybe<Scalars['String']>;
  merchant_agreement_accepted?: InputMaybe<Scalars['Boolean']>;
  merchant_agreement_ip_address?: InputMaybe<Scalars['AWSIPAddress']>;
  merchant_agreement_timestamp?: InputMaybe<Scalars['AWSDateTime']>;
  merchant_agreement_user_agent?: InputMaybe<Scalars['String']>;
  refund_policy?: InputMaybe<Refund_Policy>;
  statement_descriptor?: InputMaybe<Scalars['String']>;
  volume_distribution_by_business_type?: InputMaybe<VolumeDistributionByBusinessTypeInput>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  line1?: InputMaybe<Scalars['String']>;
  line2?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export enum AuthProvider {
  ApiKey = 'apiKey',
  Iam = 'iam',
  Oidc = 'oidc',
  UserPools = 'userPools'
}

export type AuthRule = {
  allow: AuthStrategy;
  groupClaim?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupsField?: InputMaybe<Scalars['String']>;
  identityClaim?: InputMaybe<Scalars['String']>;
  mutations?: InputMaybe<Array<InputMaybe<ModelMutation>>>;
  operations?: InputMaybe<Array<InputMaybe<ModelOperation>>>;
  ownerField?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<AuthProvider>;
  queries?: InputMaybe<Array<InputMaybe<ModelQuery>>>;
};

export enum AuthStrategy {
  Groups = 'groups',
  Owner = 'owner',
  Private = 'private',
  Public = 'public'
}

export enum AwsS3Action {
  Delete = 'DELETE',
  Get = 'GET',
  Put = 'PUT'
}

export enum Bank_Account_Type {
  Ck = 'CK',
  CorpCk = 'CORP_CK',
  CorpSv = 'CORP_SV',
  Sv = 'SV'
}

export enum Business_Type {
  Corp = 'CORP',
  Gov = 'GOV',
  International = 'INTERNATIONAL',
  Llc = 'LLC',
  Partnership = 'PARTNERSHIP',
  SoleProprietorship = 'SOLE_PROPRIETORSHIP',
  TaxExempt = 'TAX_EXEMPT',
  Trust = 'TRUST'
}

export type BackofficeKeyData = {
  __typename?: 'BackofficeKeyData';
  created_date?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  entity_level?: Maybe<BackofficeKeyEntityLevel>;
  key_name?: Maybe<Scalars['String']>;
  last_accessed_date?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
};

export type BackofficeKeyDataWithKey = {
  __typename?: 'BackofficeKeyDataWithKey';
  api_key?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  entity_level?: Maybe<BackofficeKeyEntityLevel>;
  key_name?: Maybe<Scalars['String']>;
  last_accessed_date?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
};

export enum BackofficeKeyEntityLevel {
  Merchant = 'MERCHANT',
  Partner = 'PARTNER',
  System = 'SYSTEM'
}

export type Business = {
  __typename?: 'Business';
  additional_underwriting_data?: Maybe<AdditionalUnderwritingData>;
  underwriting_data?: Maybe<UnderwritingData>;
};

export type BusinessBank = {
  __typename?: 'BusinessBank';
  account_number?: Maybe<Scalars['String']>;
  account_type?: Maybe<Bank_Account_Type>;
  bank_code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type BusinessBankInput = {
  account_number?: InputMaybe<Scalars['String']>;
  account_type?: InputMaybe<Bank_Account_Type>;
  bank_code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type BusinessInput = {
  additional_underwriting_data?: InputMaybe<AdditionalUnderwritingDataInput>;
  underwriting_data?: InputMaybe<UnderwritingDataInput>;
};

export type BusinessOwner = {
  __typename?: 'BusinessOwner';
  address?: Maybe<Address>;
  dob?: Maybe<Scalars['AWSDate']>;
  email?: Maybe<Scalars['AWSEmail']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  middle_initial?: Maybe<Scalars['String']>;
  percentage_ownership?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['AWSPhone']>;
  primary_owner?: Maybe<Scalars['Boolean']>;
  tax_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uid: Scalars['ID'];
};

export type BusinessOwnerInput = {
  address?: InputMaybe<AddressInput>;
  dob?: InputMaybe<Scalars['AWSDate']>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_initial?: InputMaybe<Scalars['String']>;
  percentage_ownership?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['AWSPhone']>;
  primary_owner?: InputMaybe<Scalars['Boolean']>;
  tax_id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  uid: Scalars['ID'];
};

export type CsvInput = {
  end_time?: InputMaybe<Scalars['AWSDateTime']>;
  locale?: InputMaybe<Scalars['String']>;
  merchant_uid?: InputMaybe<Scalars['String']>;
  report_name_prefix?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  start_time?: InputMaybe<Scalars['AWSDateTime']>;
};

export type CsvOutput = {
  __typename?: 'CSVOutput';
  bucket_name?: Maybe<Scalars['String']>;
  filepath?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['String']>;
  total_rows?: Maybe<Scalars['Int']>;
};

export enum CallToActionType {
  Book = 'BOOK',
  Donate = 'DONATE',
  Pay = 'PAY'
}

export type CardPresentPayment = {
  __typename?: 'CardPresentPayment';
  amount?: Maybe<Scalars['Int']>;
  card_brand?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<Scalars['String']>;
  failure_reason?: Maybe<Array<Maybe<Scalars['String']>>>;
  last_four?: Maybe<Scalars['String']>;
  service_fee?: Maybe<Scalars['Int']>;
  status?: Maybe<TransactionStatus>;
  transaction_id?: Maybe<Scalars['String']>;
};

export type CardVolumeDistribution = {
  __typename?: 'CardVolumeDistribution';
  card_present_percentage?: Maybe<Scalars['Int']>;
  ecommerce_percentage?: Maybe<Scalars['Int']>;
};

export type CardVolumeDistributionInput = {
  card_present_percentage?: InputMaybe<Scalars['Int']>;
  ecommerce_percentage?: InputMaybe<Scalars['Int']>;
};

export type Device = {
  __typename?: 'Device';
  device_description?: Maybe<Scalars['String']>;
  device_id?: Maybe<Scalars['String']>;
  device_name?: Maybe<Scalars['String']>;
  device_properties?: Maybe<Scalars['AWSJSON']>;
  is_active?: Maybe<Scalars['Boolean']>;
  merchant_uid?: Maybe<Scalars['String']>;
  processor?: Maybe<Scalars['String']>;
  processor_device_id?: Maybe<Scalars['String']>;
};

export type DeviceInput = {
  device_description: Scalars['String'];
  device_id?: InputMaybe<Scalars['String']>;
  device_name: Scalars['String'];
  device_properties?: InputMaybe<Scalars['AWSJSON']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  merchant_uid: Scalars['String'];
  processor: Scalars['String'];
  processor_device_id: Scalars['String'];
};

export type DisputeEvidence = {
  __typename?: 'DisputeEvidence';
  dispute_id?: Maybe<Scalars['String']>;
  evidence_date?: Maybe<Scalars['AWSDateTime']>;
  filename?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['ID']>;
  status?: Maybe<EvidenceStatus>;
  updated_date?: Maybe<Scalars['AWSDateTime']>;
};

export type Document = {
  __typename?: 'Document';
  attachment_id: Scalars['ID'];
  doc_type: Support_Document_Type;
  name: Scalars['String'];
};

export type DocumentInput = {
  body: Scalars['String'];
  doc_type: Support_Document_Type;
  mime_type: Scalars['String'];
  name: Scalars['String'];
};

export enum EvidenceStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED'
}

export enum FeeMode {
  Interchange = 'INTERCHANGE',
  MerchantFee = 'MERCHANT_FEE',
  ServiceFee = 'SERVICE_FEE'
}

export type FeeModelDetailInput = {
  ach_basis?: InputMaybe<Scalars['Int']>;
  ach_fixed?: InputMaybe<Scalars['Int']>;
  card_basis?: InputMaybe<Scalars['Int']>;
  card_fixed?: InputMaybe<Scalars['Int']>;
};

export type FeeModelInput = {
  interchange_plus?: InputMaybe<Scalars['Boolean']>;
  merchant_fee?: InputMaybe<FeeModelDetailInput>;
  service_fee?: InputMaybe<FeeModelDetailInput>;
  service_fee_min?: InputMaybe<FeeModelDetailInput>;
};

export type HostedCheckout = {
  __typename?: 'HostedCheckout';
  accepted_payment_methods?: Maybe<AcceptedPaymentMethodsEnum>;
  account_code?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  api_key?: Maybe<Scalars['String']>;
  call_to_action?: Maybe<CallToActionType>;
  checkout_type?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  fee_mode?: Maybe<FeeMode>;
  invoice_id?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  merchant_name?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['AWSJSON']>;
  origin?: Maybe<Scalars['String']>;
  payment_description?: Maybe<Scalars['String']>;
  payment_name?: Maybe<Scalars['String']>;
  payment_parameters?: Maybe<Scalars['String']>;
  payor?: Maybe<Payor>;
  recurring_id?: Maybe<Scalars['String']>;
  require_phone?: Maybe<Scalars['Boolean']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  account_code?: Maybe<Scalars['String']>;
  api_key?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<Scalars['String']>;
  due_by?: Maybe<Scalars['AWSDate']>;
  fee_mode?: Maybe<FeeMode>;
  invoice_amount?: Maybe<Scalars['Int']>;
  invoice_date?: Maybe<Scalars['AWSDate']>;
  invoice_description?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['String']>;
  invoice_name?: Maybe<Scalars['String']>;
  is_secure?: Maybe<Scalars['Boolean']>;
  merchant_invoice_number?: Maybe<Scalars['String']>;
  merchant_name?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  offline_transactions?: Maybe<Array<Maybe<OfflineTransaction>>>;
  payor?: Maybe<Payor>;
  reference?: Maybe<Scalars['String']>;
  require_payor_address?: Maybe<Scalars['Boolean']>;
  security_pin?: Maybe<Scalars['String']>;
  settings?: Maybe<InvoiceSettings>;
  status?: Maybe<InvoiceStatus>;
  total_paid_amount?: Maybe<Scalars['Int']>;
};

export type InvoiceSettings = {
  __typename?: 'InvoiceSettings';
  accepted_payment_methods?: Maybe<AcceptedPaymentMethods>;
};

export enum InvoiceStatus {
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID'
}

export type Merchant = {
  __typename?: 'Merchant';
  ach_active?: Maybe<Scalars['Boolean']>;
  card_active?: Maybe<Scalars['Boolean']>;
  cash_active?: Maybe<Scalars['Boolean']>;
  is_system?: Maybe<Scalars['Boolean']>;
  merchant_name?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  parent_merchant_uid?: Maybe<Scalars['String']>;
  submitted_onboarding?: Maybe<Scalars['Boolean']>;
};

export type MerchantInput = {
  ach_processor?: InputMaybe<Scalars['String']>;
  api_key?: InputMaybe<Scalars['String']>;
  card_processor?: InputMaybe<Scalars['String']>;
  cash_processor?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  fee_model?: InputMaybe<FeeModelInput>;
  is_system?: InputMaybe<Scalars['Boolean']>;
  merchant_name: Scalars['String'];
  merchant_uid?: InputMaybe<Scalars['String']>;
  parent_merchant_uid?: InputMaybe<Scalars['String']>;
};

export type MerchantOnboarding = {
  __typename?: 'MerchantOnboarding';
  bank?: Maybe<BusinessBank>;
  business?: Maybe<Business>;
  business_owners?: Maybe<Array<Maybe<BusinessOwner>>>;
  is_locked?: Maybe<Scalars['Boolean']>;
  merchant_uid: Scalars['ID'];
  needs_docs?: Maybe<Scalars['Boolean']>;
  ticket_id?: Maybe<Scalars['Int']>;
  user_email?: Maybe<Scalars['String']>;
  user_full_name?: Maybe<Scalars['String']>;
};

export type MerchantOnboardingInput = {
  bank?: InputMaybe<BusinessBankInput>;
  business?: InputMaybe<BusinessInput>;
  business_owners?: InputMaybe<Array<InputMaybe<BusinessOwnerInput>>>;
  merchant_uid: Scalars['ID'];
  needs_docs?: InputMaybe<Scalars['Boolean']>;
  ticket_id?: InputMaybe<Scalars['Int']>;
  user_email?: InputMaybe<Scalars['String']>;
  user_full_name?: InputMaybe<Scalars['String']>;
};

export enum ModelMutation {
  Create = 'create',
  Delete = 'delete',
  Update = 'update'
}

export type ModelMutationMap = {
  create?: InputMaybe<Scalars['String']>;
  delete?: InputMaybe<Scalars['String']>;
  update?: InputMaybe<Scalars['String']>;
};

export enum ModelOperation {
  Create = 'create',
  Delete = 'delete',
  Read = 'read',
  Update = 'update'
}

export enum ModelQuery {
  Get = 'get',
  List = 'list'
}

export type ModelQueryMap = {
  get?: InputMaybe<Scalars['String']>;
  list?: InputMaybe<Scalars['String']>;
};

export enum ModelSubscriptionLevel {
  Off = 'off',
  On = 'on',
  Public = 'public'
}

export type ModelSubscriptionMap = {
  level?: InputMaybe<ModelSubscriptionLevel>;
  onCreate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  onDelete?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  onUpdate?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBackOfficeKey?: Maybe<BackofficeKeyDataWithKey>;
  createCardPresentCompletion?: Maybe<CardPresentPayment>;
  createCardPresentPayment?: Maybe<Scalars['String']>;
  createDevice?: Maybe<Device>;
  createMerchant?: Maybe<Merchant>;
  createMerchantOnboarding?: Maybe<Scalars['Boolean']>;
  createMerchantOnboardingDocumentSubmission?: Maybe<Scalars['Boolean']>;
  createMerchantOnboardingStatusChange?: Maybe<Scalars['Boolean']>;
  createMerchantOnboardingSubmission?: Maybe<Scalars['Boolean']>;
  createNativeApp?: Maybe<Scalars['Boolean']>;
  createNotification?: Maybe<Notification>;
  createSendDisputeEvidence?: Maybe<Scalars['Boolean']>;
  createSuccessOnboardingEmail?: Maybe<Scalars['Boolean']>;
  createSupportNote?: Maybe<Scalars['Boolean']>;
  createUser?: Maybe<User>;
  deleteBackOfficeKey?: Maybe<Scalars['Boolean']>;
  deleteDevice?: Maybe<Scalars['Boolean']>;
  deleteDisputeEvidence?: Maybe<Scalars['Boolean']>;
  deleteNativeApp?: Maybe<Scalars['Boolean']>;
  updateBackOfficeKey?: Maybe<Scalars['Boolean']>;
  updateNativeApp?: Maybe<Scalars['Boolean']>;
  updateRecurringPaymentMethod?: Maybe<RecurringPayment>;
  updateTransactionAccountCode?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateBackOfficeKeyArgs = {
  entity_level: BackofficeKeyEntityLevel;
  key_name?: InputMaybe<Scalars['String']>;
  merchant_uid: Scalars['String'];
};


export type MutationCreateCardPresentCompletionArgs = {
  amount?: InputMaybe<Scalars['Int']>;
  card_brand?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['AWSDateTime']>;
  failure_reason?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  last_four?: InputMaybe<Scalars['String']>;
  service_fee?: InputMaybe<Scalars['Int']>;
  status: TransactionStatus;
  transaction_id: Scalars['String'];
};


export type MutationCreateCardPresentPaymentArgs = {
  account_code?: InputMaybe<Scalars['String']>;
  amount: Scalars['Int'];
  device_id: Scalars['String'];
  fee?: InputMaybe<Scalars['Int']>;
  fee_mode?: InputMaybe<FeeMode>;
  invoice_id?: InputMaybe<Scalars['String']>;
  merchant_uid: Scalars['String'];
  metadata?: InputMaybe<Scalars['AWSJSON']>;
  payment_parameters_name?: InputMaybe<Scalars['String']>;
  payor?: InputMaybe<PayorInput>;
  payor_id?: InputMaybe<Scalars['String']>;
  receipt_description?: InputMaybe<Scalars['String']>;
  recurring_id?: InputMaybe<Scalars['String']>;
  reference?: InputMaybe<Scalars['String']>;
  send_receipt?: InputMaybe<Scalars['Boolean']>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type MutationCreateDeviceArgs = {
  input: DeviceInput;
};


export type MutationCreateMerchantArgs = {
  input: MerchantInput;
};


export type MutationCreateMerchantOnboardingArgs = {
  is_form_completed: Scalars['Boolean'];
  merchant_onboarding: MerchantOnboardingInput;
  support_note?: InputMaybe<SupportNoteInput>;
};


export type MutationCreateMerchantOnboardingDocumentSubmissionArgs = {
  attachment_ids: Array<InputMaybe<Scalars['ID']>>;
  merchant_uid: Scalars['ID'];
  processor: Scalars['String'];
  ticket_id: Scalars['ID'];
};


export type MutationCreateMerchantOnboardingStatusChangeArgs = {
  merchant_uid: Scalars['ID'];
  want_to_ask_for_docs?: InputMaybe<Scalars['Boolean']>;
  want_to_unlock_data?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateMerchantOnboardingSubmissionArgs = {
  merchant_uid: Scalars['ID'];
  processor: Scalars['String'];
};


export type MutationCreateNativeAppArgs = {
  input: NativeAppInput;
};


export type MutationCreateNotificationArgs = {
  context_id: NotificationContextId;
  error?: InputMaybe<Scalars['String']>;
  merchant_uid: Scalars['ID'];
  primary_value?: InputMaybe<Scalars['String']>;
  secondary_value?: InputMaybe<Scalars['String']>;
};


export type MutationCreateSendDisputeEvidenceArgs = {
  dispute_id: Scalars['String'];
  merchant_uid?: InputMaybe<Scalars['ID']>;
};


export type MutationCreateSuccessOnboardingEmailArgs = {
  merchant_uid: Scalars['ID'];
};


export type MutationCreateSupportNoteArgs = {
  merchant_uid: Scalars['ID'];
  support_note: SupportNoteInput;
  ticket_id: Scalars['ID'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationDeleteBackOfficeKeyArgs = {
  key_name: Scalars['String'];
  merchant_uid: Scalars['String'];
};


export type MutationDeleteDeviceArgs = {
  device_id: Scalars['String'];
  merchant_uid: Scalars['String'];
};


export type MutationDeleteDisputeEvidenceArgs = {
  dispute_id: Scalars['String'];
  filename: Scalars['String'];
  merchant_uid: Scalars['ID'];
};


export type MutationDeleteNativeAppArgs = {
  app_id: Scalars['String'];
  merchant_uid: Scalars['String'];
};


export type MutationUpdateBackOfficeKeyArgs = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  key_name: Scalars['String'];
  merchant_uid: Scalars['String'];
};


export type MutationUpdateNativeAppArgs = {
  input: NativeAppInput;
};


export type MutationUpdateRecurringPaymentMethodArgs = {
  payment_method_id: Scalars['String'];
  recurring_hash: Scalars['String'];
};


export type MutationUpdateTransactionAccountCodeArgs = {
  account_code: Scalars['String'];
  transaction_id: Scalars['String'];
};

export type NativeApp = {
  __typename?: 'NativeApp';
  app_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  device_check_key?: Maybe<Scalars['String']>;
  device_check_kid?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  platform?: Maybe<NativeAppPlatform>;
};

export type NativeAppInput = {
  app_id: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  device_check_key?: InputMaybe<Scalars['String']>;
  device_check_kid?: InputMaybe<Scalars['String']>;
  merchant_uid: Scalars['String'];
  platform: NativeAppPlatform;
};

export enum NativeAppPlatform {
  Android = 'ANDROID',
  Apple = 'APPLE'
}

export type Notification = {
  __typename?: 'Notification';
  context_id: NotificationContextId;
  error?: Maybe<Scalars['String']>;
  merchant_uid: Scalars['ID'];
  primary_value?: Maybe<Scalars['String']>;
  secondary_value?: Maybe<Scalars['String']>;
};

export enum NotificationContextId {
  DisputeChange = 'DISPUTE_CHANGE',
  DisputeEvidenceDeleted = 'DISPUTE_EVIDENCE_DELETED',
  DisputeEvidenceSent = 'DISPUTE_EVIDENCE_SENT',
  DisputeEvidenceUploaded = 'DISPUTE_EVIDENCE_UPLOADED'
}

export enum Ownership_Type {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type OfflineTransaction = {
  __typename?: 'OfflineTransaction';
  amount?: Maybe<Scalars['Int']>;
  instance_id?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  transaction_date?: Maybe<Scalars['AWSDate']>;
  type?: Maybe<OfflineTransactionType>;
};

export enum OfflineTransactionType {
  Ach = 'ACH',
  Card = 'CARD',
  Cash = 'CASH',
  Other = 'OTHER'
}

export type PaymentLink = {
  __typename?: 'PaymentLink';
  accepted_payment_methods?: Maybe<AcceptedPaymentMethodsEnum>;
  account_code?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  amount_is_variable?: Maybe<Scalars['Boolean']>;
  api_key?: Maybe<Scalars['String']>;
  call_to_action?: Maybe<CallToActionType>;
  currency?: Maybe<Scalars['String']>;
  custom_success_message?: Maybe<Scalars['String']>;
  fee_mode?: Maybe<FeeMode>;
  is_active?: Maybe<Scalars['Boolean']>;
  max_amount?: Maybe<Scalars['Int']>;
  merchant_name?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  min_amount?: Maybe<Scalars['Int']>;
  payment_description?: Maybe<Scalars['String']>;
  payment_name?: Maybe<Scalars['String']>;
  redirect_url?: Maybe<Scalars['String']>;
  require_phone?: Maybe<Scalars['Boolean']>;
};

export type PaymentMethodToken = {
  __typename?: 'PaymentMethodToken';
  address_line1?: Maybe<Scalars['String']>;
  address_line2?: Maybe<Scalars['String']>;
  card_brand?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  exp_date?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['Boolean']>;
  last_four?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['String']>;
  payment_type?: Maybe<PaymentType>;
  payor?: Maybe<Payor>;
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type PaymentParameters = {
  __typename?: 'PaymentParameters';
  denied_after_date?: Maybe<Scalars['AWSDateTime']>;
  denied_before_date?: Maybe<Scalars['AWSDateTime']>;
  enabled: Scalars['Boolean'];
  expires_in?: Maybe<Scalars['Int']>;
  maximum_amount?: Maybe<Scalars['Int']>;
  maximum_occurrence?: Maybe<Scalars['Int']>;
  minimum_amount?: Maybe<Scalars['Int']>;
  payment_parameters_name?: Maybe<Scalars['String']>;
  payment_parameters_type: Scalars['String'];
  recurrence_period?: Maybe<Scalars['String']>;
  validation_link_url?: Maybe<Scalars['AWSURL']>;
};

export enum PaymentType {
  Ach = 'ACH',
  Card = 'CARD',
  Cash = 'CASH'
}

export type Payor = {
  __typename?: 'Payor';
  address_line1?: Maybe<Scalars['String']>;
  address_line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  merchant_uid?: Maybe<Scalars['String']>;
  payor_id?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type PayorInput = {
  address_line1?: InputMaybe<Scalars['String']>;
  address_line2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  full_name?: InputMaybe<Scalars['String']>;
  merchant_uid?: InputMaybe<Scalars['String']>;
  payor_id?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postal_code?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  backOfficeKey?: Maybe<Array<Maybe<BackofficeKeyData>>>;
  defaultPaymentParameters?: Maybe<PaymentParameters>;
  disputeEvidence: Array<Maybe<DisputeEvidence>>;
  disputeEvidenceTokens: Array<Maybe<Scalars['AWSURL']>>;
  generateCSV?: Maybe<CsvOutput>;
  hostedCheckoutPage?: Maybe<HostedCheckout>;
  invoiceCheckoutPage?: Maybe<Invoice>;
  merchantOnboarding?: Maybe<MerchantOnboarding>;
  nativeApps?: Maybe<Array<Maybe<NativeApp>>>;
  paymentLinkCheckoutPage?: Maybe<PaymentLink>;
  recurringUpdatePage?: Maybe<RecurringUpdateDetails>;
  supportConversation?: Maybe<SupportConversation>;
};


export type QueryBackOfficeKeyArgs = {
  entity_level: BackofficeKeyEntityLevel;
  merchant_uid: Scalars['String'];
};


export type QueryDisputeEvidenceArgs = {
  dispute_id: Scalars['String'];
  merchant_uid?: InputMaybe<Scalars['ID']>;
};


export type QueryDisputeEvidenceTokensArgs = {
  action: AwsS3Action;
  dispute_id: Scalars['String'];
  filenames: Array<InputMaybe<Scalars['String']>>;
  merchant_uid?: InputMaybe<Scalars['ID']>;
  mime_types?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGenerateCsvArgs = {
  csvInput: CsvInput;
};


export type QueryHostedCheckoutPageArgs = {
  session_id: Scalars['String'];
};


export type QueryInvoiceCheckoutPageArgs = {
  invoice_id: Scalars['String'];
};


export type QueryMerchantOnboardingArgs = {
  merchant_uid: Scalars['ID'];
};


export type QueryPaymentLinkCheckoutPageArgs = {
  link_id: Scalars['String'];
};


export type QueryRecurringUpdatePageArgs = {
  recurring_hash: Scalars['String'];
};


export type QuerySupportConversationArgs = {
  merchant_uid: Scalars['ID'];
  ticket_id: Scalars['ID'];
};

export enum Refund_Policy {
  Exchange = 'EXCHANGE',
  None = 'NONE',
  Other = 'OTHER',
  Thirty = 'THIRTY'
}

export enum RecurringInterval {
  Annual = 'ANNUAL',
  BiAnnual = 'BI_ANNUAL',
  BiWeekly = 'BI_WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  Weekly = 'WEEKLY'
}

export type RecurringPayment = {
  __typename?: 'RecurringPayment';
  account_code?: Maybe<Scalars['String']>;
  amount_per_payment?: Maybe<Scalars['Int']>;
  created_date?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<Scalars['String']>;
  fee_mode?: Maybe<FeeMode>;
  fee_per_payment?: Maybe<Scalars['Int']>;
  is_active?: Maybe<Scalars['Boolean']>;
  is_processing?: Maybe<Scalars['Boolean']>;
  merchant_uid?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['AWSJSON']>;
  next_payment_date?: Maybe<Scalars['AWSDate']>;
  payment_interval?: Maybe<RecurringInterval>;
  payment_method?: Maybe<PaymentMethodToken>;
  payor?: Maybe<Payor>;
  prev_payment_date?: Maybe<Scalars['AWSDate']>;
  recurring_description?: Maybe<Scalars['String']>;
  recurring_id?: Maybe<Scalars['String']>;
  recurring_name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  remaining_payments?: Maybe<Scalars['Int']>;
  status?: Maybe<RecurringStatus>;
  total_amount_per_payment?: Maybe<Scalars['Int']>;
};

export enum RecurringStatus {
  InstrumentFailure = 'INSTRUMENT_FAILURE',
  Success = 'SUCCESS',
  SystemFailure = 'SYSTEM_FAILURE'
}

export type RecurringUpdateDetails = {
  __typename?: 'RecurringUpdateDetails';
  amount?: Maybe<Scalars['Int']>;
  api_key?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  merchant_name?: Maybe<Scalars['String']>;
  payor_id?: Maybe<Scalars['String']>;
  payor_name?: Maybe<Scalars['String']>;
  recurring_description?: Maybe<Scalars['String']>;
  recurring_name?: Maybe<Scalars['String']>;
};

export enum Support_Document_Type {
  BankStatement = 'BANK_STATEMENT',
  BusinessAddressVerification = 'BUSINESS_ADDRESS_VERIFICATION',
  BusinessRegistration = 'BUSINESS_REGISTRATION',
  DriversLicenseBack = 'DRIVERS_LICENSE_BACK',
  DriversLicenseFront = 'DRIVERS_LICENSE_FRONT',
  IdentificationCardBack = 'IDENTIFICATION_CARD_BACK',
  IdentificationCardFront = 'IDENTIFICATION_CARD_FRONT',
  Other = 'OTHER',
  Passport = 'PASSPORT',
  PciDocument = 'PCI_DOCUMENT',
  TaxDocument = 'TAX_DOCUMENT'
}

export type Subscription = {
  __typename?: 'Subscription';
  createCardPresentCompletion?: Maybe<CardPresentPayment>;
  createNotification?: Maybe<Notification>;
};


export type SubscriptionCreateCardPresentCompletionArgs = {
  transaction_id: Scalars['String'];
};


export type SubscriptionCreateNotificationArgs = {
  context_id?: InputMaybe<NotificationContextId>;
  error?: InputMaybe<Scalars['String']>;
  merchant_uid: Scalars['ID'];
  primary_value?: InputMaybe<Scalars['String']>;
  secondary_value?: InputMaybe<Scalars['String']>;
};

export type SupportConversation = {
  __typename?: 'SupportConversation';
  support_notes?: Maybe<Array<Maybe<SupportNote>>>;
  ticket_id: Scalars['ID'];
};

export type SupportNote = {
  __typename?: 'SupportNote';
  body?: Maybe<Scalars['String']>;
  support_docs?: Maybe<Array<Maybe<Document>>>;
};

export type SupportNoteInput = {
  body?: InputMaybe<Scalars['String']>;
  support_docs?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
};

export type TimestampConfiguration = {
  createdAt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export enum TransactionStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  PartiallyRefunded = 'PARTIALLY_REFUNDED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Settled = 'SETTLED',
  Succeeded = 'SUCCEEDED'
}

export type UnderwritingData = {
  __typename?: 'UnderwritingData';
  annual_card_volume?: Maybe<Scalars['Int']>;
  business_address?: Maybe<Address>;
  business_name?: Maybe<Scalars['String']>;
  business_type?: Maybe<Business_Type>;
  doing_business_as?: Maybe<Scalars['String']>;
  has_accepted_credit_cards_previously?: Maybe<Scalars['Boolean']>;
  incorporation_date?: Maybe<Scalars['AWSDate']>;
  max_transaction_amount?: Maybe<Scalars['Int']>;
  mcc?: Maybe<Scalars['String']>;
  ownership_type?: Maybe<Ownership_Type>;
  phone?: Maybe<Scalars['AWSPhone']>;
  tax_id?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['AWSURL']>;
};

export type UnderwritingDataInput = {
  annual_card_volume?: InputMaybe<Scalars['Int']>;
  business_address?: InputMaybe<AddressInput>;
  business_name?: InputMaybe<Scalars['String']>;
  business_type?: InputMaybe<Business_Type>;
  doing_business_as?: InputMaybe<Scalars['String']>;
  has_accepted_credit_cards_previously?: InputMaybe<Scalars['Boolean']>;
  incorporation_date?: InputMaybe<Scalars['AWSDate']>;
  max_transaction_amount?: InputMaybe<Scalars['Int']>;
  mcc?: InputMaybe<Scalars['String']>;
  ownership_type?: InputMaybe<Ownership_Type>;
  phone?: InputMaybe<Scalars['AWSPhone']>;
  tax_id?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['AWSURL']>;
};

export type User = {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  user_status?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UserInput = {
  bypass_merchant_validation?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['AWSEmail'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  merchant_uid?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['AWSPhone']>;
  user_pool: UserPool;
};

export enum UserPool {
  Merchant = 'MERCHANT',
  Partner = 'PARTNER',
  System = 'SYSTEM'
}

export type VolumeDistributionByBusinessType = {
  __typename?: 'VolumeDistributionByBusinessType';
  business_to_business_volume_percentage?: Maybe<Scalars['Int']>;
  business_to_consumer_volume_percentage?: Maybe<Scalars['Int']>;
  other_volume_percentage?: Maybe<Scalars['Int']>;
};

export type VolumeDistributionByBusinessTypeInput = {
  business_to_business_volume_percentage?: InputMaybe<Scalars['Int']>;
  business_to_consumer_volume_percentage?: InputMaybe<Scalars['Int']>;
  other_volume_percentage?: InputMaybe<Scalars['Int']>;
};
