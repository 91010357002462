import {appSyncQuery} from "./index";
import {
    AcceptedPaymentMethodsEnum,
    CallToActionType,
    MoveDirection,
    PaymentLink, PaymentLinks,
    QueryPair,
    SortDirection
} from "../GraphQL/backoffice";
import {GraphQLResult} from "@aws-amplify/api-graphql";

const listString = `query listPaymentLinks($direction: MoveDirection = FORWARD, $limit: Int = 10, $offset: String, $offset_id: String, $query: SqlQuery) {
  paymentLinks(direction: $direction, limit: $limit, offset: $offset, offset_id: $offset_id, query: $query) {
    total_row_count
    items {
      merchant_uid
      link_id
      link_url
      link_name
      payment_name
      payment_description
      amount
      currency
      fee_mode
      amount_is_variable
      min_amount
      max_amount
      require_phone
      call_to_action
      accepted_payment_methods
      account_code
      custom_success_message
      redirect_url
      is_active
      created_date
    }
  }
}
`

export const list = (order: SortDirection, offset: PaymentLink | null, limit: number, filter: Array<QueryPair>, direction: MoveDirection): Promise<GraphQLResult<{paymentLinks: PaymentLinks}>> => {
    let sort = [{key: 'created_date', direction: order}]
    const queryObject = {
        query_list: filter,
        sort_list: sort,
    }
    const variables = {
        query: queryObject,
        offset_id: offset?.link_id,
        offset: offset?.created_date,
        limit: limit,
        direction: direction
    }
    return appSyncQuery(listString, variables)
}

const createString = `mutation CreatePaymentLink($accepted_payment_methods: AcceptedPaymentMethodsEnum = ALL, 
                                          $account_code: String, 
                                          $amount: Int, 
                                          $amount_is_variable: Boolean, 
                                          $call_to_action: CallToActionType, 
                                          $currency: String, 
                                          $custom_success_message: String, 
                                          $fee_mode: FeeMode, 
                                          $link_name: String!, 
                                          $max_amount: Int, 
                                          $merchant_uid: String!, 
                                          $min_amount: Int, 
                                          $payment_description: String, 
                                          $payment_name: String!, 
                                          $redirect_url: String, 
                                          $require_phone: Boolean) {
  createPaymentLink(input: { accepted_payment_methods: $accepted_payment_methods, 
                             account_code: $account_code, 
                             amount: $amount, 
                             amount_is_variable: $amount_is_variable, 
                             call_to_action: $call_to_action, 
                             currency: $currency, 
                             custom_success_message: $custom_success_message, 
                             fee_mode: $fee_mode, 
                             link_name: $link_name, 
                             max_amount: $max_amount, 
                             merchant_uid: $merchant_uid, 
                             min_amount: $min_amount, 
                             payment_description: $payment_description, 
                             payment_name: $payment_name, 
                             redirect_url: $redirect_url, 
                             require_phone: $require_phone}) {
    accepted_payment_methods
    account_code
    amount
    amount_is_variable
    call_to_action
    created_date
    currency
    custom_success_message
    fee_mode
    is_active
    link_id
    link_name
    link_url
    max_amount
    merchant_uid
    min_amount
    payment_description
    payment_name
    redirect_url
    require_phone
  }
}
`

export type CreatePaymentLinkInput = {
    accepted_payment_methods: AcceptedPaymentMethodsEnum,
    account_code: String,
    amount: Number,
    amount_is_variable: Boolean,
    call_to_action: CallToActionType,
    currency: String,
    custom_success_message: String,
    fee_mode: String,
    link_name: String,
    max_amount: Number,
    merchant_uid: String,
    min_amount: Number,
    payment_description: String,
    payment_name: String,
    redirect_url: String,
    require_phone: Boolean
}

export const create = (variables: CreatePaymentLinkInput): Promise<GraphQLResult<{createPaymentLink: PaymentLink}>> => {
    return appSyncQuery(createString, variables)
}

const updateString = `mutation UpdatePaymentLink($accepted_payment_methods: AcceptedPaymentMethodsEnum, 
                                          $account_code: String, 
                                          $amount: Int, 
                                          $amount_is_variable: Boolean, 
                                          $call_to_action: CallToActionType, 
                                          $currency: String, 
                                          $custom_success_message: String, 
                                          $fee_mode: FeeMode, 
                                          $is_active: Boolean, 
                                          $link_id: String!, 
                                          $link_name: String, 
                                          $max_amount: Int, 
                                          $merchant_uid: String!, 
                                          $min_amount: Int, 
                                          $payment_description: String, 
                                          $payment_name: String, 
                                          $redirect_url: String, 
                                          $require_phone: Boolean) {
  updatePaymentLink(input: { accepted_payment_methods: $accepted_payment_methods, 
                             account_code: $account_code, 
                             amount: $amount, 
                             amount_is_variable: $amount_is_variable, 
                             call_to_action: $call_to_action, 
                             currency: $currency, 
                             custom_success_message: $custom_success_message, 
                             fee_mode: $fee_mode, 
                             is_active: $is_active, 
                             link_id: $link_id, 
                             link_name: $link_name, 
                             max_amount: $max_amount, 
                             merchant_uid: $merchant_uid, 
                             min_amount: $min_amount, 
                             payment_description: $payment_description, 
                             payment_name: $payment_name, 
                             redirect_url: $redirect_url, 
                             require_phone: $require_phone}) {
    merchant_uid
    link_id
    link_url
    link_name
    payment_name
    payment_description
    amount
    currency
    fee_mode
    amount_is_variable
    min_amount
    max_amount
    require_phone
    call_to_action
    accepted_payment_methods
    account_code
    custom_success_message
    redirect_url
    is_active
    created_date
  }
}`

export type UpdatePaymentLinkInput = {
    accepted_payment_methods?: AcceptedPaymentMethodsEnum,
    account_code?: String,
    amount?: Number,
    amount_is_variable?: Boolean,
    call_to_action?: CallToActionType,
    currency?: String,
    custom_success_message?: String,
    fee_mode?: String,
    is_active?: Boolean,
    link_id: String,
    link_name?: String,
    max_amount?: Number,
    merchant_uid: String,
    min_amount?: Number,
    payment_description?: String,
    payment_name?: String,
    redirect_url?: String,
    require_phone?: Boolean
}

export const update = (variables: UpdatePaymentLinkInput): Promise<GraphQLResult<{updatePaymentLink: PaymentLink}>> => {
    return appSyncQuery(updateString, variables)
}