// import { createClient } from 'graphql-ws';
import * as merchant from './merchant';
import * as transactions from './transactions';
import * as settlements from './settlements';
import * as chargebacks from './chargebacks';
import * as subscribe from './subscribe';
import * as invoices from './invoices';
import * as recurring from "./recurring";
import * as secretkey from './secretkey';
import * as native from './native';
import * as cardpresent from './cardpresent'
import * as paymentlinks from './paymentlinks';
import * as onboarding from './onboarding';
import * as disputes from './disputes';
import * as subscritpion from './subscription';

import { API, graphqlOperation } from "aws-amplify";
import {Observable} from "zen-observable-ts";

// const handleResponse = (response, onError = false) => {
//     if (response.status !== 200) {
//         return response
//             .json()
//             .then(error => {
//                 return Promise.reject(onError ? onError : error);
//             })
//             .catch(err => {
//                 if (response.status === 401 || response.status === 403) {
//                     window.location = '/unauthorized'
//                     return Promise.reject(
//                         `unauthorized access attempt:${response.status}`
//                     );
//                 }
//                 else if (response.status === 401 || response.status === 404) {
//                     return Promise.reject(
//                         `Not Found:${response.status}`
//                     );
//                 }
//                 else {
//                     return Promise.reject(onError ? onError : err);
//                 }
//             })
//     }
//     else {
//         try {
//             return Promise.resolve(response.json());
//         }
//         catch (error) {
//             return Promise.reject(error);
//         }
//     }
// };

// const formatToken = (token) => {
//     const strigifiedToken = JSON.stringify(token);
//     const encodedToken = btoa(strigifiedToken);
//     return encodeURI(encodedToken);
// };
//
// const createSocket = (accessToken, idToken, connect) => {
//     let token = {
//         jwtAccessToken: accessToken,
//         jwtIdToken: idToken,
//         userPoolId: process.env.REACT_APP_USER_POOL
//     };
//     const formattedToken = formatToken(token);
//     return createClient({
//         url: `${process.env.REACT_APP_API_ENDPOINT}${process.env.REACT_APP_PARTNER}/?security_tokens=${formattedToken}`,
//         connectionAckWaitTimeout: Infinity,
//         lazy: false,
//         onNonLazyError: (errorOrCloseEvent) => {
//             console.log(errorOrCloseEvent)
//         },
//         on: {
//             connected: (socket) => {
//                 connect(socket)
//             },
//             pong: (payload) => {
//                 console.log('pong',payload)
//             },
//             error: (err) => {
//                 console.error(err);
//             },
//         }
//       });
// };
//
// const socketQuery = async(websocket, query) => {
//     return await new Promise((resolve, reject) => {
//         let result;
//         websocket.subscribe(
//           {
//             query: query,
//           },
//           {
//             next: (data) => result = data,
//             error: reject,
//             complete: () => resolve(result),
//           },
//         )
//       });
// };
//
// const socketSubsciption = async(websocket, action, query) => {
//     return await new Promise((resolve, reject) => {
//         websocket.subscribe(
//           {
//             query: query
//           },
//           {
//             next: (data) => action(data),
//             error: reject,
//             complete: () => resolve(true)
//           }
//         )
//       });
// };

const configureAPI = (internal: boolean) => {
    // Lets us swap between our two endpoints for calling our internal and external APIs
    if(internal) {
        // @ts-ignore
        API._options.aws_appsync_graphqlEndpoint = API._options.endpoints[1]
        // @ts-ignore
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.endpoints[1]
        // @ts-ignore
        API._graphqlApi._api._options.aws_appsync_graphqlEndpoint = API._options.endpoints[1]
    } else {
        // @ts-ignore
        API._options.aws_appsync_graphqlEndpoint = API._options.endpoints[0]
        // @ts-ignore
        API._graphqlApi._options.aws_appsync_graphqlEndpoint = API._options.endpoints[0]
        // @ts-ignore
        API._graphqlApi._api._options.aws_appsync_graphqlEndpoint = API._options.endpoints[0]
    }
}


const appSyncQuery = (query: string, variables: object | undefined, internal: boolean = false): Promise<any> => {
    configureAPI(internal)
    return API.graphql(graphqlOperation(query, variables || {})) as Promise<any>
};

const appSyncSubscription = <T extends object>(query: string, variables: object | undefined, onNext: (value: T) => void, onError: (error: any) => void, internal: boolean = false) => {
    configureAPI(internal)
    let call = API.graphql(graphqlOperation(query, variables || {})) as Observable<object>
    return call.subscribe({
        next: (response: { value: { data: T } }) => {
            onNext(response.value.data)
        },
        error: onError
    })
};


export {
    appSyncQuery,
    appSyncSubscription,
    // createSocket,
    // socketQuery,
    // socketSubsciption,
    // handleResponse,
    merchant,
    transactions,
    settlements,
    chargebacks,
    invoices,
    secretkey,
    subscribe,
    recurring,
    native,
    cardpresent,
    paymentlinks,
    onboarding,
    disputes,
    subscritpion
}