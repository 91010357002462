import {appSyncQuery} from "./index";
import {GraphQLResult} from "@aws-amplify/api-graphql";
import {MutationCreateNativeAppArgs, MutationUpdateNativeAppArgs, NativeApp} from "../GraphQL/internal_types";

const listString = `{
  nativeApps {
    app_id
    description
    device_check_key
    device_check_kid
    merchant_uid
    platform
  }
}
`

export const list = (): Promise<GraphQLResult<{nativeApps: Array<NativeApp>}>> => {
    return appSyncQuery(listString, {}, true)
}

const createString = `mutation CreateNativeApp($platform: NativeAppPlatform!, $merchant_uid: String!, $device_check_kid: String, $device_check_key: String, $description: String $app_id: String!) {
  createNativeApp(input: {app_id: $app_id, description: $description, device_check_key: $device_check_key, device_check_kid: $device_check_kid, platform: $platform, merchant_uid: $merchant_uid})
}`

export const create = (variables: MutationCreateNativeAppArgs): Promise<GraphQLResult<boolean>> => {
    const parsedVariables = variables.input
    return appSyncQuery(createString, parsedVariables, true)
}

const updateString = `mutation UpdateNativeApp($platform: NativeAppPlatform!, $merchant_uid: String!, $device_check_kid: String, $device_check_key: String, $description: String, $app_id: String!) {
  updateNativeApp(input: {app_id: $app_id, description: $description, device_check_key: $device_check_key, device_check_kid: $device_check_kid, platform: $platform, merchant_uid: $merchant_uid })
}`

export const update = (variables: MutationUpdateNativeAppArgs): Promise<GraphQLResult<boolean>> => {
    const parsedVariables = variables.input
    return appSyncQuery(updateString, parsedVariables, true)
}

const deleteString = `mutation DeleteString($app_id: String!, $merchant_uid: String!) {
  deleteNativeApp(app_id: $app_id, merchant_uid: $merchant_uid)
}`

export const deleteApp = (merchant_uid: string, app_id: string): Promise<GraphQLResult<boolean>> => {
    const parsedVariables = {
        merchant_uid: merchant_uid,
        app_id: app_id
    }
    return appSyncQuery(deleteString, parsedVariables, true)
}